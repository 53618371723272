import React, { useState, useLayoutEffect } from 'react';
import { Link, useNavigate, } from "react-router-dom";
import validator from 'validator';
import { Formik, Form, Field } from 'formik';

import api from "../api/api";
import {setToken} from '../helpers/index'
import ClosedEye from '../component/svgComponents/ClosedEye'
import OpenedEye from '../component/svgComponents/OpenedEye'
import ModalAbout from '../component/Modal/ModalAbout'
import Header from '../component/Headers/Header';

const MESSAGE_EMAIL_IS_INVALID = "Email не соответствует требованиям";
const MESSAGE_PASSWORD= "Пароль должен состоять из одной строчной, прописной буквы и цифры, не менее 8 символов";

export default function LoginPage () {
    const navigate = useNavigate();    
    const [netWorkError,setNetWorkError] = useState()
    const [serverError,setServerError] = useState();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [openModal,setOpenModal] = useState(false);
    const successEmail  = window.location.search;

    useLayoutEffect(() => {  
      let formSelector = document.getElementsByClassName("form-wrapper")[0]
      formSelector.setAttribute("style", "margin-bottom: 0;")
      setTimeout(()=> {
        formSelector.removeAttribute("style")
      }, 0)      
    }, []) 

    const togglePopup = () => {
      setOpenModal(!openModal);
    }
    
    const [register, setRegister] = useState(() => {
        return {
            email: "",
            password: "",           
        }
    })
    const initialErrorsList = {
      email: "",
      password: "",          
    }

    const [errorsList, setErrorsList] = useState(initialErrorsList)  


    const removeFieldError = (field) => {
        setErrorsList(prevState => ({...prevState, [`${field}`]: ''}))
    } 

    const changeInputRegister = event => {   
        const changedValue = event.target.value
        const fieldName = event.target.name
        removeFieldError(fieldName);
        setRegister(prev => {
            return {
                ...prev,
                [fieldName]: changedValue,
            }
        })
    }


    const submitCheck = async (event) => {
      event.preventDefault();
      let wasError = false;
      const {
        password,      
      } = register;
      const email = register.email.trim();

      if (!validator.isEmail(email)) {
        setErrorsList(prevState => ({...prevState, email: MESSAGE_EMAIL_IS_INVALID}))
        wasError = true;
      }

      if (!validator.isStrongPassword(password, {minSymbols: 0})) {
        setErrorsList(prevState => ({...prevState, password: MESSAGE_PASSWORD}))
        wasError = true;
      }
      if (wasError) {
        return;
      }
      try {
        const res = await api.post(
          "/auth/login/", {
           email: register.email,
           password: register.password,
        });
        if (res?.data) {
          setToken(res.data.token);                                             
          navigate('/user-page',{state:{email:email}});
        }
      } catch (e) {
        if(e.message === "Network Error") {
          setNetWorkError(e.message);
        };
        if(e.message === "Request failed with status code 401") {
          setServerError(e.response.data.error);
        };
        setServerError(e.response.data.error); // ask Dima about DRY

      }
    } 

    return (
      <> 
      <Header/>
        <Formik            
        initialValues={register}
        onSubmit={submitCheck}
      >        
          <div className='form-wrapper form-wrapper-login'>
            <div className='header__description'>
              <h1>Добро пожаловать в веб-версию сервиса международного ЭДО SimpSign Online!</h1>
              <h3>Здесь вы сможете подписывать электронные документы с вашими партнерами в Казахстане</h3>
            </div>   
            <Form className='form-container container-login'>
              {successEmail && (<div className="valid-email">{successEmail === '?successEmail' ? "Вы успешно подтвердили Email": "Вы успешно изменили пароль"}</div>)}
              <h1 className='form-title'>Вход в сервис</h1>
              <div className='field-container'>
                <p className="field-title">Email</p>    
                <Field
                  className={Boolean(errorsList?.email) || serverError?.email ? "input_error": "input"}
                  label="Email"
                  name="email"
                  type="email" 
                  onChange={changeInputRegister}              
                  value={register.email}            
                />
                {Boolean(errorsList?.email) ? (<div className="valid-error">{errorsList.email}</div>) : null}   
                {(<div className="valid-error">{serverError ? serverError:""}</div>)}
              </div>

              <div className='field-container'>
                <p className="field-title">Пароль</p>            
                <Field
                  className={Boolean(errorsList?.password) || serverError?.password ? "input_error": "input"}
                  label="password"
                  name="password"
                  type={passwordVisible ? "text" : "password"}   
                  onChange={changeInputRegister}
                  value={register.password}     
                />
              {
                <div 
                  className={Boolean(errorsList?.password) || serverError?.password ? "password-image-error__login": "password-image__login"}
                  onClick={() => setPasswordVisible(prevState => !prevState)}
                >
                  {
                    passwordVisible
                      ? <OpenedEye/>
                      : <ClosedEye />
                  }
                </div>
              }
              {Boolean(errorsList?.password) ? (<div className="valid-error">{errorsList.password}</div>) : null}               
            </div>
            <Link className='form__link login' to="/forgot-password">Забыли пароль?</Link>
            <div className="form-buttons-container">
              <button
                className="form-button button-login"
                type="submit"
               onClick={submitCheck}
              >
               Войти              
              </button>

            </div>
            <Link className='form__link' to="/registration">Зарегистрироваться</Link>
            </Form>
            <p className="form-error">
               { netWorkError ? <span className="valid-error">На сервере произошла непредвиденная ошибка. Обратитесь в техническую поддержку.</span> :""}
             </p>
             <p className='about'>Воспользуйтесь всеми преимуществами сервиса SimpSign не покидая<br/> систему 1С, <Link className='form__link' onClick={() => setOpenModal(true)}>подробнее...</Link></p>
          <div className='footer'>
            <div className='footer__register'>
              <p>Узнать больше о сервисе SimpSign</p>
              <a href='https://simpsign.com/' target='blank'>www.simpsign.com</a>
            </div>
            
          </div>       
        
    </div>             
      </Formik>
    <div>    
      {openModal && <ModalAbout
        handleClose={togglePopup}
      />}
  </div>
      </>
    )    
}
