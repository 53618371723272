import React from 'react'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import DeleteIcon from '../svgComponents/DeleteIcon';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '31.91vw',
    height: '21.88vw',
    bgcolor: 'background.paper',
    padding: '2.69vw 3.88vw',
    fontFamily: 'IBM Plex Sans', 
    boxSizing: 'border-box',
    outline: 'none'
  };

export default function ModalChoose({isOpen, setIsOpen, documentsID, setIsOpenCerts, modalType, deleteFiles}) {
    const declOfNum = (number, words) => {  
        return words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
    }
    const handleClickToSign = () => {
        setIsOpenCerts(true)
        setIsOpen(false);
    }
    const handleClickToDelete = () => {
        setIsOpen(false);
        deleteFiles();
    }
    return (
        <div>
            <Modal
            open={isOpen}
            onClose={()=>setIsOpen(false)}
        >
            <Box sx={style}>
                <div className='signature-success-close' onClick={() => setIsOpen(false)}><DeleteIcon /></div>
                {documentsID.length % 10 === 1 && documentsID.length % 100 !== 11
                    ?
                    <div className='signature-chosen-text'>Выбран {documentsID.length} {declOfNum(documentsID.length, ['пакет', 'пакета', 'пакетов'])} документов <br/> { (modalType === 'Sign') ? <span> на подпись </span> : <span>для удаления из списка</span> }</div>
                    :
                    <div className='signature-chosen-text'>Выбрано {documentsID.length} {declOfNum(documentsID.length, ['пакет', 'пакета', 'пакетов'])} документов <br/> {(modalType === 'Sign') ? <span> на подпись </span> : <span>для удаления из списка</span>}</div>
                }
                {
                    (modalType === 'Sign') 
                    ?
                    <div className='signature-sign' onClick={handleClickToSign}>Подписать</div>
                    :
                    <div className='signature-sign' onClick={handleClickToDelete}>Удалить</div>
                }
               
                <div onClick={() => setIsOpen(false)} className='signature-cancel'>Отменить</div>
            </Box>
        </Modal>
        </div>
    )
}
