import React, {useEffect} from 'react'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import api from "../../api/api";
import Logo from '../svgComponents/Logo';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '43.12vw',
    height: '32.98vw',
    bgcolor: 'background.paper',
    padding: '5.39vw 3.88vw',
    fontFamily: 'IBM Plex Sans', 
    boxSizing: 'border-box',
    outline: 'none',
  };

export default function ModalWelcome({isOpen, setIsOpen, user}) {

    useEffect(() => {
        if (user) {
            if (user.is_first_login) {
                setIsOpen(true);
                api.post("/update-first-login");
            } 
        }
    }, [user])
    if(!isOpen) return null;

    return (
        <div>
            <Modal
            open={isOpen}
        >
            <Box sx={style}>
                <div className='welcome-block'>
                    <Logo />
                    <div className='welcome-block__title'>Добро пожаловать в сервис <br />международного ЭДО SimpSign Online!</div>
                    <div className='welcome-block__text'>Здесь вы можете обмениваться электронными документами с вашими <br />контрагентами в Казахстане. Начните с отправки им приглашения!</div>
                    <div className='welcome-block__cancel' onClick={() => setIsOpen(!isOpen)}>Начать</div>
                </div>
            </Box>
        </Modal>
        </div>
    )
}
