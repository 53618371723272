import React, { useContext } from 'react';

import { TabsContext } from '../../helpers/TabsContext';
import DocumentListIcon from '../svgComponents/DocumentListIcon';

const NoDocumentOutGoing = () => {
    const {
        currentTab,
        currentPageTab,
        setCurrentTab,
        setCurrentPageTab
    } = useContext(TabsContext);
    const handleClick = () => {
        setCurrentTab(0);
        setCurrentPageTab(0)
      }
    return (
        <div className='no-document out-coming'>
            <DocumentListIcon/>
            <h1 style={{textAlign: 'center'}}>Здесь ничего нет, потому что вы пока не отправили <br/>ни одного документа</h1>
            <button
                className="form-button button-login"
                type="submit"
               onClick={handleClick}
              >
              Отправить документы            
              </button>
        </div>
    );
};

export default NoDocumentOutGoing;