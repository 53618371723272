import React, {memo} from "react";

const SvgComponent = (props) => (
    <div
        style={{
            width: '1.4vw',
            height:'1.4vw',
            borderRadius: '0.7vw',
            backgroundColor: "#EB5757",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: 1,
            marginLeft: 10,
        }}
    >
        <span style={{
            textAlign: "center",
            fontSize: '0.7vw',
            color: "white",
        }}>
            {props.content}
        </span>
    </div>
)
const Memo = memo(SvgComponent)
export default Memo
