import React, { useContext } from "react";
import globalStyle from "../../DocumentComponents/customStyleButton";
import { Badge, Box, Stack, Typography } from "@mui/material";
import { ScrollContext } from "../../../helpers/ScrollContext";

const TableHeaders = () => {
    const columns = [
        {
            id: 'organizations',
            label: 'Организации',
            width: '32%',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'companies',
            label: 'ID компании',
            width: '29.2%',
            format: (value) => value.toLocaleString('en-US'),
        },
        {
            id: 'inns',
            label: 'ИНН',
            width: '12.7%',
            format: (value) => value,
        },
        {
            id: 'kpps',
            label: 'КПП',
            width: '12.7%',
            format: (value) => value,
        },
        {
            id: 'allowSending',
            label: 'Разрешить отправку',
            width: '12%',
            format: (value) => value.toFixed(2),
        },
    ];

    const { scroll } = useContext(ScrollContext);

    return (
        <Stack sx={{ ...globalStyle.styleStack, borderBottom: scroll ? '' : '1px solid #E1E4E8' }}>
            {columns.map((column) => (
                <Box
                    width={column.width}
                    height={'50px'}
                    justifyContent={'left'}
                    display={'flex'}
                    key={column.id}
                    alignItems={'end'}
                    paddingBottom={'9px'}
                >
                    <Typography variant="inherit" sx={globalStyle.styleHead} key={column.id}>
                        {column.label}
                    </Typography>
                </Box>
            ))}
        </Stack>
    );
};

export default TableHeaders;
