import ErrorIcon from '../svgComponents/ErrorIcon';
import React from 'react';
import { Box, Modal } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '31.91vw',
    height: 'auto',
    bgcolor: 'background.paper',
    padding: '2.69vw 3.88vw',
    fontFamily: 'IBM Plex Sans',
    boxSizing: 'border-box',
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
};

const ModalExpireCertificate = ({ isOpen, setIsOpenModalExpireCertificate }) => {
    return (
        <div>
            <Modal open={isOpen} onClose={() => setIsOpenModalExpireCertificate(false)}>
                <Box sx={style}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <div>
                            <ErrorIcon />
                        </div>
                        <Box sx={{ paddingRight: 0 }}>
                            <p className='signature-error-text'>
                                Пакет документов не может быть отправлен на перевалидацию - истек срок действия сертификата подписи, использованной
                                при его подписании.
                            </p>

                            <p className='signature-error-text'>Необходимо отправить пакет документов на подписание повторно.</p>
                        </Box>
                    </Box>
                    <button className='signature-sign' onClick={() => setIsOpenModalExpireCertificate(false)}>
                        Закрыть
                    </button>
                </Box>
            </Modal>
        </div>
    );
};

export default ModalExpireCertificate;
