import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.25vw"
      height="1.25vw"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill="#212529"
        d="M18.7 9.7l-6 6c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3l-6-6c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l5.3 5.3 5.3-5.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4z"
      ></path>
      <mask
        id="mask0_1569_1565"
        style={{ maskType: "luminance" }}
        width="14"
        height="8"
        x="5"
        y="8"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M18.7 9.7l-6 6c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3l-6-6c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l5.3 5.3 5.3-5.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4z"
        ></path>
      </mask>
      <g mask="url(#mask0_1569_1565)">
        <path
          fill="#212529"
          fillRule="evenodd"
          d="M0 0h24v24H0V0z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
