import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.25vw"
      height="1.3vw"
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        fill="#212529"
        fillRule="evenodd"
        d="M11.6 17.4c-.1 0-.2-.1-.3-.2l-4-4c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3V2.5c0-.6.4-1 1-1s1 .4 1 1v11.6l2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4 4c-.05.05-.1.075-.15.1-.05.025-.1.05-.15.1-.1.1-.3.1-.4.1-.1 0-.3 0-.4-.1zM22 20.5v-3c0-.6-.4-1-1-1s-1 .4-1 1v3c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1v-3c0-.6-.4-1-1-1s-1 .4-1 1v3c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0_973_314"
        style={{ maskType: "luminance" }}
        width="20"
        height="23"
        x="2"
        y="1"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M11.6 17.4c-.1 0-.2-.1-.3-.2l-4-4c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3V2.5c0-.6.4-1 1-1s1 .4 1 1v11.6l2.3-2.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-4 4c-.05.05-.1.075-.15.1-.05.025-.1.05-.15.1-.1.1-.3.1-.4.1-.1 0-.3 0-.4-.1zM22 20.5v-3c0-.6-.4-1-1-1s-1 .4-1 1v3c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1v-3c0-.6-.4-1-1-1s-1 .4-1 1v3c0 1.7 1.3 3 3 3h14c1.7 0 3-1.3 3-3z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_973_314)">
        <path
          fill="#212529"
          fillRule="evenodd"
          d="M0 .5h24v24H0V.5z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;

