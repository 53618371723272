import React from "react";

const SvgComponent = (props) => ( 
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='2.6vw'
      height='2.6vw'
      fill='none'
      viewBox='0 0 50 50'
      {...props}
    >
      <path
        fill='#212529'
        fillRule='evenodd'
        d='M25 47.917A22.85 22.85 0 0047.916 25 22.85 22.85 0 0025 2.084 22.85 22.85 0 002.083 25 22.85 22.85 0 0025 47.917zm.416-18.959c.834-.208 7.709-2.708 7.709-8.124 0-3.542-2.292-6.667-5.625-7.709-4.375-1.666-9.167.625-10.625 5-.209 1.042.208 2.292 1.25 2.292 1.041.416 2.291-.209 2.708-1.25.833-2.084 3.333-3.334 5.417-2.5 1.666.625 2.708 2.083 2.708 3.958 0 2.292-3.542 3.959-4.792 4.375-1.041.417-1.666 1.667-1.25 2.709.209.833 1.042 1.458 1.875 1.458.209 0 .417 0 .625-.209zM43.75 25A18.672 18.672 0 0025 6.25 18.672 18.672 0 006.25 25 18.672 18.672 0 0025 43.75 18.672 18.672 0 0043.75 25zM26.04 33.75c.209 0 .417.209.417.209.208.208.417.416.208.624.209.209.209.626.209.834 0 .625-.209 1.041-.625 1.458-.417.417-.834.625-1.459.625-.625 0-1.041-.208-1.458-.625-.417-.416-.625-.833-.625-1.458 0-.244.072-.417.13-.56a.747.747 0 00.078-.273c0-.209.209-.417.417-.626.208-.208.417-.416.625-.416.417-.209.833-.209 1.25-.209.12.12.17.171.232.193a.57.57 0 00.185.016c.104 0 .156.052.208.104.052.052.104.104.208.104z'
        clipRule='evenodd'
      ></path>
      <mask
        id='mask0_956_1940'
        style={{ maskType: "luminance" }}
        width='46'
        height='46'
        x='2'
        y='2'
        maskUnits='userSpaceOnUse'
      >
        <path
          fill='#fff'
          fillRule='evenodd'
          d='M25 47.917A22.85 22.85 0 0047.916 25 22.85 22.85 0 0025 2.084 22.85 22.85 0 002.083 25 22.85 22.85 0 0025 47.917zm.416-18.959c.834-.208 7.709-2.708 7.709-8.124 0-3.542-2.292-6.667-5.625-7.709-4.375-1.666-9.167.625-10.625 5-.209 1.042.208 2.292 1.25 2.292 1.041.416 2.291-.209 2.708-1.25.833-2.084 3.333-3.334 5.417-2.5 1.666.625 2.708 2.083 2.708 3.958 0 2.292-3.542 3.959-4.792 4.375-1.041.417-1.666 1.667-1.25 2.709.209.833 1.042 1.458 1.875 1.458.209 0 .417 0 .625-.209zM43.75 25A18.672 18.672 0 0025 6.25 18.672 18.672 0 006.25 25 18.672 18.672 0 0025 43.75 18.672 18.672 0 0043.75 25zM26.04 33.75c.209 0 .417.209.417.209.208.208.417.416.208.624.209.209.209.626.209.834 0 .625-.209 1.041-.625 1.458-.417.417-.834.625-1.459.625-.625 0-1.041-.208-1.458-.625-.417-.416-.625-.833-.625-1.458 0-.244.072-.417.13-.56a.747.747 0 00.078-.273c0-.209.209-.417.417-.626.208-.208.417-.416.625-.416.417-.209.833-.209 1.25-.209.12.12.17.171.232.193a.57.57 0 00.185.016c.104 0 .156.052.208.104.052.052.104.104.208.104z'
          clipRule='evenodd'
        ></path>
      </mask>
      <g mask='url(#mask0_956_1940)'>
        <path
          fill='#fff'
          fillRule='evenodd'
          d='M0 0h50v50H0V0z'
          clipRule='evenodd'
        ></path>
      </g>
    </svg>
  );

  export default SvgComponent

