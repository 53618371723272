import * as React from 'react';

import Button from '@mui/material/Button';
import {  Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import styled from '@emotion/styled';

import globalStyle from '../DocumentComponents/customStyleButton'

const CustomButton = styled(Button)(({ theme }) => ({
  '&:hover, &.Mui-focusVisible': {
    backgroundColor: '#8AB528',
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

export default function ButtonsPanelInComing() {
  const columns = [
    {
      id: 'company',
      label: 'Организации',
      width:'40%',
      format: (value) => value.toLocaleString('en-US'),
    },
    {
      id: 'bin',
      label: 'БИН',
      width: '20%',
      format: (value) => value,
    },
    
    {
      id: 'date',
      label: 'Дата',
      width: '20%',
      format: (value) => value,
    },
    {
      id: 'status',
      label: 'Статус приглашения',
      width: '20%',
      format: (value) => value,
    }
  ];

  return (
    <Box  sx={{ position:"sticky",top: 0, zIndex: 1, background: '#e5e5e5', width:'100%'}}>
        <Stack sx={{overflowX: 'initial',backgroundColor:'white', width:'calc(1600/1920*100vw)', flexDirection:'row', alignItems:'center', mt:'1.04vw', height:'3.1vw'}}>
            {columns.map((column) => (
              <Box borderBottom={'1px solid #E1E4E8'} width={column.width} height={'50px'} justifyContent={'left'} display={'flex'} key={column.id} alignItems={'end'} paddingBottom={'9px'}>
                <Typography
                  variant='inherit'
                  sx={globalStyle.styleHead}
                  key={column.id}                
                >
                  {column.label}
                </Typography>
              </Box>
            ))}
        </Stack>
    </Box>

  );
}