import React, {memo} from "react";

function Icon(props) {
  const {color = '#BDBDBD'} = props;
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.25vw'
      height='1.25vw'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <path
        fill={color}
        d='M22.637 12.195l-9.32 8.858C11.881 22.416 10.038 23 8.194 23a7.78 7.78 0 01-5.12-1.947 6.634 6.634 0 010-9.637l9.422-8.956C13.419 1.584 14.75 1 16.082 1c1.331 0 2.663.487 3.584 1.46 2.049 1.947 2.049 4.965 0 6.912l-9.422 8.955c-1.23 1.169-3.175 1.169-4.302 0-.512-.486-.82-1.265-.82-2.044 0-.779.308-1.557.922-2.044l8.706-8.274c.41-.39 1.024-.39 1.434 0 .41.389.41.973 0 1.362l-8.706 8.275c-.205.194-.307.39-.307.681 0 .292.102.487.307.682.41.389 1.024.389 1.434 0l9.423-8.956c1.23-1.168 1.23-3.018 0-4.089-1.127-1.07-3.175-1.07-4.302 0L4.61 12.876c-2.048 1.947-2.048 4.965 0 6.912a5.257 5.257 0 007.272 0l9.423-8.956c.41-.39 1.024-.39 1.434 0 .41.39.308.973-.102 1.363z'
      ></path>
      <mask
        id='mask0_956_718'
        style={{ maskType: "luminance" }}
        width='1.25vw'
        height='1.25vw'
        x='1'
        y='1'
        maskUnits='userSpaceOnUse'
      >
        <path
          fill='#fff'
          d='M22.637 12.195l-9.32 8.858C11.881 22.416 10.038 23 8.194 23a7.78 7.78 0 01-5.12-1.947 6.634 6.634 0 010-9.637l9.422-8.956C13.419 1.584 14.75 1 16.082 1c1.331 0 2.663.487 3.584 1.46 2.049 1.947 2.049 4.965 0 6.912l-9.422 8.955c-1.23 1.169-3.175 1.169-4.302 0-.512-.486-.82-1.265-.82-2.044 0-.779.308-1.557.922-2.044l8.706-8.274c.41-.39 1.024-.39 1.434 0 .41.389.41.973 0 1.362l-8.706 8.275c-.205.194-.307.39-.307.681 0 .292.102.487.307.682.41.389 1.024.389 1.434 0l9.423-8.956c1.23-1.168 1.23-3.018 0-4.089-1.127-1.07-3.175-1.07-4.302 0L4.61 12.876c-2.048 1.947-2.048 4.965 0 6.912a5.257 5.257 0 007.272 0l9.423-8.956c.41-.39 1.024-.39 1.434 0 .41.39.308.973-.102 1.363z'
        ></path>
      </mask>
      {/* <g mask='url(#mask0_956_718)'>
        <path
          fill='#BDBDBD'
          fillRule='evenodd'
          d='M0 0h24v24H0V0z'
          clipRule='evenodd'
        ></path>
      </g> */}
    </svg>
  );
}

export default memo(Icon);
