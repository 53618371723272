import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.04vw"
      height="1.04vw"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#212529"
        d="M17.25 10.583l-5 5a.756.756 0 01-.583.25.757.757 0 01-.584-.25.806.806 0 010-1.166l3.584-3.584H3.333c-.5 0-.833-.333-.833-.833 0-.5.333-.833.833-.833h11.334l-3.584-3.584a.806.806 0 010-1.166.806.806 0 011.167 0l5 5c.083.083.167.166.167.25.083.166.083.416 0 .666 0 .084-.084.167-.167.25z"
      ></path>
      <mask
        id="mask0_1445_6753"
        style={{ maskType: "luminance" }}
        width="16"
        height="12"
        x="2"
        y="4"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M17.25 10.583l-5 5a.756.756 0 01-.583.25.757.757 0 01-.584-.25.806.806 0 010-1.166l3.584-3.584H3.333c-.5 0-.833-.333-.833-.833 0-.5.333-.833.833-.833h11.334l-3.584-3.584a.806.806 0 010-1.166.806.806 0 011.167 0l5 5c.083.083.167.166.167.25.083.166.083.416 0 .666 0 .084-.084.167-.167.25z"
        ></path>
      </mask>
      <g mask="url(#mask0_1445_6753)">
        <path
          fill="#212529"
          fillRule="evenodd"
          d="M0 0h20v20H0V0z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
