import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.3vw"
      height="8.9vw"
      fill="none"
      viewBox="0 0 160 172"
    >
      <path
        fill="#000"
        d="M56.854 160.741a.938.938 0 01-.84-.525c-.228-.461-22.778-46.312-29.782-63.78C20.436 81.978 11.08 32.337 10.685 30.23a.945.945 0 01.775-1.105l111.746-17.775c.3-.05.609.055.821.276a.944.944 0 01.245.835c-.084.432-8.41 43.28-5.626 57.078 3.368 16.687 32.687 60.416 32.983 60.856a.944.944 0 01-.503 1.428l-93.998 28.875a.901.901 0 01-.274.042zM12.708 30.835c1.354 7.102 9.97 51.697 15.263 64.898 6.497 16.203 26.467 57.012 29.372 62.931l92.027-28.27c-4.632-6.965-29.376-44.695-32.562-60.48-2.611-12.937 3.948-48.988 5.37-56.493L12.708 30.835z"
      ></path>
      <path
        fill="#8AB528"
        d="M55.227 42.349l9.216-3.792 4.142 24.197-16.483 3.538 3.125-23.943zM70.852 44.377h12.5l-2.123 12.099-8.242 1.769-2.135-13.868zM93.769 132.911a.941.941 0 01-.317-1.827c5.187-1.876 6.334-8.374 7.346-14.107.877-4.967 1.724-9.663 5.538-9.382 1.898.137 4.548 2.119 6.83 4.798 1.336-1.693 2.904-2.962 4.533-3.095 4.771-.386 16.212 9.144 17.505 10.232a.946.946 0 01.117 1.327.934.934 0 01-1.321.116c-4.839-4.072-13.156-10.047-16.148-9.797-1.022.084-2.288 1.117-3.488 2.735 1.89 2.592 3.322 5.576 3.487 8.122.2 3.099-1.479 5.171-4.728 5.838-1.892.387-2.93-.323-3.471-.986-1.644-2.023-.739-6.263.657-9.558a23.692 23.692 0 011.74-3.344c-2.055-2.514-4.429-4.407-5.847-4.509-2.003-.154-2.68 2.862-3.556 7.833-1.047 5.927-2.35 13.304-8.559 15.549a.965.965 0 01-.318.055zm19.492-17.316a22.267 22.267 0 00-1.227 2.469c-1.416 3.342-1.807 6.55-.929 7.628.116.142.465.574 1.643.333 2.345-.481 3.372-1.711 3.233-3.87-.134-2.042-1.247-4.418-2.72-6.56z"
      ></path>
      <path
        fill="#FEA000"
        d="M52.101 85.506a.937.937 0 01-.917-.755.94.94 0 01.732-1.11l6.126-1.245a.942.942 0 01.373 1.846l-6.126 1.245a.976.976 0 01-.188.02zm12.251-2.49a.942.942 0 01-.184-1.865l6.126-1.244a.94.94 0 01.371 1.847l-6.126 1.244a.948.948 0 01-.187.019zm12.253-2.488a.942.942 0 01-.185-1.865l6.125-1.245a.935.935 0 011.105.736.941.941 0 01-.732 1.11l-6.126 1.245a.95.95 0 01-.187.019zm12.252-2.49a.942.942 0 01-.185-1.865l6.126-1.245a.937.937 0 011.105.737.941.941 0 01-.733 1.11l-6.126 1.244a.965.965 0 01-.187.019zM56.853 98.743a.938.938 0 01-.917-.756.941.941 0 01.732-1.11l6.126-1.244a.942.942 0 01.373 1.846l-6.126 1.244a.917.917 0 01-.188.02zm12.252-2.49a.939.939 0 01-.918-.755.941.941 0 01.733-1.11l6.126-1.245a.942.942 0 01.373 1.847l-6.127 1.244a.955.955 0 01-.187.019zm12.252-2.49a.942.942 0 01-.185-1.865l6.126-1.244a.938.938 0 011.105.737.941.941 0 01-.733 1.11l-6.125 1.243a.955.955 0 01-.188.02zm12.251-2.489a.942.942 0 01-.185-1.865l6.127-1.245a.937.937 0 011.105.737.941.941 0 01-.733 1.11l-6.126 1.244a.87.87 0 01-.188.02zM62.101 109.468a.937.937 0 01-.917-.755.94.94 0 01.732-1.11l6.126-1.244a.942.942 0 01.373 1.845l-6.126 1.245a.938.938 0 01-.188.019zm12.252-2.489a.94.94 0 01-.918-.755.941.941 0 01.733-1.11l6.126-1.245a.942.942 0 01.373 1.846l-6.127 1.244a.866.866 0 01-.187.02zm12.252-2.49a.942.942 0 01-.185-1.865l6.125-1.245a.942.942 0 01.373 1.847l-6.126 1.244a.999.999 0 01-.187.019zM98.857 102a.942.942 0 01-.185-1.865l6.126-1.245a.936.936 0 011.105.736.94.94 0 01-.733 1.11l-6.126 1.245a.928.928 0 01-.187.019z"
      ></path>
    </svg>
  );
}

export default Icon;