import React from 'react';

import CounterPartyIcon from './svgComponents/CounterPartyIcon';

const CounterPartyButton = (props) => {
    const { isActive, onClick = () => {} } = props;
    return (
        <div onClick={onClick} className={`counterparty-button ${isActive ? 'active' : ''}`}>
            <CounterPartyIcon className='counterparty-icon'/>
            Контрагенты
        </div>
    );
};

export default CounterPartyButton;