import * as crypto from "crypto-pro";

const getObjectDescriptionByTitle = (certsInfoArray, objectTitle) => {
        const currentObject = certsInfoArray.find((item) => item.title === objectTitle);
        return currentObject?.description || '';
};

const getMonthByNumber = (monthNumber) => {
        switch (monthNumber) {
            case 1:
                return 'января';
            case 2:
                return 'февраля';
            case 3:
                return 'марта';
            case 4:
                return 'апреля';
            case 5:
                return 'мая';
            case 6:
                return 'июня';
            case 7:
                return 'июля';
            case 8:
                return 'августа';
            case 9:
                return 'сентября';
            case 10:
                return 'октября';
            case 11:
                return 'ноября';
            case 12:
                return 'декабря';
            default:
                return 'неверный месяц';
        }
    };

    const transformCertDate = (stringDate) => {
        const objDate = new Date(stringDate);
        const day = objDate.getDate().toString();
        const month = getMonthByNumber(objDate.getMonth() + 1);
        const year = objDate.getFullYear();
        return day + ' ' + month + ' ' + year;
    };

     const getKpp = (description) => {
        const regex = /KPP=(\d+)/;
        const match = description.match(regex);
        if (match && match[1]) {
            return match[1];
        } else {
            return '';
        }
    };

const getModifiedUserCertificates = async () => {
        const receivedCerts = await crypto.getUserCertificates();
        const modifiedCerts = await Promise.all(
            receivedCerts.map(async (receivedCert) => {
                const subjectInfoArray = await receivedCert.getOwnerInfo();
                const inn = getObjectDescriptionByTitle(subjectInfoArray, 'ИНН') || 'Не найдено';
                const owner = getObjectDescriptionByTitle(subjectInfoArray, 'Владелец') || 'Не найдено';
                const kpp = getKpp(getObjectDescriptionByTitle(subjectInfoArray, 'Неструктурированное имя')) || 'Не найдено';
                const expiredDate = transformCertDate(receivedCert.validTo) || 'Не найдено';

                return {
                    ...receivedCert,
                    inn,
                    owner,
                    kpp,
                    expiredDate,
                };
            })
        );

        return modifiedCerts;
};

export default getModifiedUserCertificates;