import React, {useState} from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material';

import globalStyle from '../DocumentComponents/customStyleButton'

const StyledPagination = styled(TablePagination)(({ theme }) => ({
  root: {

  },
  '&.MuiTablePagination-root': {
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
    fontFamily: 'IBM Plex Sans',  
  },
  '.MuiTablePagination-toolbar': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',    
  },
  '.MuiTablePagination-spacer': {
    display: 'none',
    
  },
  '.MuiInputBase-root': {
    fontFamily: 'IBM Plex Sans',  
    marginRight: 'auto',
    },

}));

const columns = [

  {
    id: 'company',
    label: 'Организации', 
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'bin',
    label: 'БИН', 
    align: 'center',
    format: (value) => value,
  },
  
  {
    id: 'date',
    label: 'Дата',
    align: 'center',
    format: (value) => value.toFixed(2),
  },
];

export default function StickyHeadTable(prop) {
  const {rows} = prop;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: 'calc(1600/1920*100vw)', overflow: 'hidden', padding: '0', borderRadius: 0}}>
      <TableContainer sx={{ maxHeight: 'auto', position: 'sticky' }}>
        <Table aria-label="sticky table" stickyHeader={true}>
          <TableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                return (
                  <TableRow hover 
                  tabIndex={-1} key={index }>
                        <TableCell sx={{...globalStyle.customStyleTable, width:'70%', paddingBottom:'16px'}}>{row.company}</TableCell>
                        <TableCell sx={{...globalStyle.customStyleTable, width:'15%', paddingBottom:'16px'}}>{row.bin}</TableCell>
                        <TableCell sx={{...globalStyle.customStyleTable, width: '15%', paddingBottom:'16px'}} >{row.date.split('T')[0].split('-').reverse().join('.')}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledPagination
        labelRowsPerPage='На странице'                
        rowsPerPageOptions={[30, 50, 100]}
        sx={{width:'100%', justifyContent: 'space-between',fontSize:'0.7vw'}}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({
          from, to, count
        }) => {  
          return `${Math.ceil(to / rowsPerPage)} из ${Math.ceil(count/ rowsPerPage)} страниц `
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}