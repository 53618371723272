import React from 'react';

function Header() {
  return (
    <div className='header'>
      <div className='header__content'>
     <a href='https://simpsign.com/' target='blank'> <img className="header-image" src={'./assets/image/SimpSign_logo.png'} alt="SimpSign_logo"/></a>
      </div>
    </div >
  );
}

export default Header;