import '../../css/Button.css';
import React from 'react';
import clsx from 'clsx';

const Button = ({ children, success, error, ...props }) => {
    return (
        <button
            className={clsx('custom-button', {
                'custom-button--success': success,
                'custom-button--error': error,
            })}
            {...props}
        >
            {children}
        </button>
    );
};

export default Button;
