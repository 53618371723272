import React from 'react';

import EmptyMessage from '../svgComponents/EmptyMessage';

const NoInvite = () => {
    return (
        <div className='no-document no-invite'>
            <EmptyMessage/>
            <h1>Новых приглашений нет</h1>
        </div>
    );
};

export default NoInvite;