import React, { useState } from "react";
import validator from 'validator'

import api from "../../api/api";
import ClosedEye from "../svgComponents/ClosedEye";
import CrossIcon from "../svgComponents/CrossIcon";
import OpenedEye from "../svgComponents/OpenedEye";

const MIN__LENGTH = 3;
const MAX__LENGTH = 15;
const INN__LENGTH = 10;
const KPP__LENGTH = 9;
const MESSAGE_EMAIL_IS_INVALID = "Email не соответствует требованиям";
const MESSAGE_identification_number_REQUIRED = "Введите ИНН компании";
//const MESSAGE_KPP_REQUIRED = "Введите КПП компании";
const MESSAGE_PASSWORD= "Пароль должен состоять из одной строчной, прописной буквы и цифры, не менее 8 символов";
const MESSAGE_PASSWORDS_MATCH = "Пароли должны совпадать";
const MESSAGE_NAME_REQUIRE = "Введите название компании";
const MESSAGE_MAX_SYMBOL = "Максимальное количество символов 15";
const MESSAGE_INN_REQUIRED = "Введите ИНН компании должен состоять из 10 символов";
const MESSAGE_KPP_REQUIRED = "Введите КПП компании должен состоять из 9 символов";

const valueUser = {
  name:{
    header:'Изменить наименование компании',
    description:'Если наименование вашей компании указано неправильно, вы можете его изменить.',
    inputTitle:'Введите наименование компании:',
    page:"name",
  },
  identification_number:{
    header:'Изменить ИНН',
    description:'Если ваш ИНН указан неправильно, вы можете его изменить.',
    inputTitle:'Введите ваш ИНН:',
    page:"identification_number",
  },
  email:{
    header:'Изменить email',
    description:'Введите новый email, на него придет письмо со ссылкой. Для подтверждения и активации пройдите по этой ссылке.',
    inputTitle:'Введите ваш новый email:', 
    page:"email",
  },
  kpp:{
    header:'Изменить КПП',
    description:'Если ваш КПП указан неправильно, вы можете его изменить.',
    inputTitle:'Введите ваш КПП:',
    page:"kpp",
  },
  password:{
    header:'Изменить пароль',
    inputTitleOld:'Текущий пароль',
    inputTitleNew:'Новый пароль',
    inputTitleRepeat:'Повторите новый пароль',
  }  
}


const ModalSettingUser = ({handleClose,field,modalAlertEmail}) => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordVisible2, setPasswordVisible2] = useState(false);
  const [passwordVisible3, setPasswordVisible3] = useState(false);
  const [netWorkError,setNetWorkError] = useState()
  const [serverError,setServerError] = useState();

  const initialErrorsList = {
    name: "",
    email: "",
    identification_number:"",
    kpp:"",
    password: "",   
    password2:"",         
    password3:"", 
  }
  const [register, setRegister] = useState(() => {
    return {
      name: "",
      email: "",
      identification_number:"",
      kpp:"",
      password: "", 
      password2:"",  
      password3:"",           
    }
})
  const [errorsList, setErrorsList] = useState(initialErrorsList);
  const isPasswordModal = field === 'Пароль';
  const isEmailModal = field === 'Email';

  const getTitle = () => {
    switch(field) {
        case 'НАИМЕНОВАНИЕ': {
          return valueUser.name;         
        }
        case 'ИНН': {
          return valueUser.identification_number;         
        }
        case 'Email': {
          return valueUser.email;          
        }
        case 'КПП': {
          return valueUser.kpp;          
        }
        case 'Пароль': {
          return valueUser.password;          
        }
        default: return valueUser.name;
    }
  }

  const removeFieldError = (field) => {
    setErrorsList(prevState => ({...prevState, [`${field}`]: ''}))
} 

const changeInputRegister = event => {   
    const changedValue = event.target.value
    const fieldName = event.target.name
    removeFieldError(fieldName);
    setRegister(prev => {
        return {
          ...prev,
          [fieldName]: changedValue,
        }
      })
    }
 
    const submitCheck = async(event) => {
      event.preventDefault();
      let wasError = false;
      const toNewObj = (register) => {
        let newObj = {}
        for (let key in register){
          if (register[key]){
            if (key === 'email') register[key] = register[key].trim();
            newObj[key] = register[key];
          }
        }
        return newObj
      }
      const newObj = toNewObj(register);
      if(newObj?.name ) {
        if(newObj?.name?.length <= MIN__LENGTH){
          setErrorsList(prevState => ({...prevState, name: MESSAGE_NAME_REQUIRE}))
        wasError = true;
        }
      }


      if(newObj?.email){
        if (!validator.isEmail(newObj?.email)) {
          setErrorsList(prevState => ({...prevState, email: MESSAGE_EMAIL_IS_INVALID}))
          wasError = true;
        }
      }

      if(newObj?.identification_number) {
        if (newObj?.identification_number?.length !== INN__LENGTH) {  
          setErrorsList(prevState => ({...prevState, identification_number: MESSAGE_INN_REQUIRED}))
          wasError = true;
        }
      }

      if(newObj?.kpp) {
      if (newObj?.kpp?.length !== KPP__LENGTH) {
        setErrorsList(prevState => ({...prevState, kpp: MESSAGE_KPP_REQUIRED}))
        wasError = true;
      }
    }
      if(newObj?.password){
        if (!validator.isStrongPassword(newObj?.password, {minSymbols: 0})) {
          setErrorsList(prevState => ({...prevState, password: MESSAGE_PASSWORD}))
          wasError = true;
        }
      }
      if(newObj?.password2){
        if (!validator.isStrongPassword(newObj?.password2, {minSymbols: 0})) {
          setErrorsList(prevState => ({...prevState, password2: MESSAGE_PASSWORD}))
          wasError = true;
        }
      }
      if(newObj?.password2 && newObj?.password3){
        if (newObj?.password2 !== newObj?.password3) {
          setErrorsList(prevState => ({...prevState, password3: MESSAGE_PASSWORDS_MATCH}))
          wasError = true;
        }
      }

      if (wasError) {
      return;
      };
      try {
        const res = await api.patch(
          "/user/update-data/", newObj
        );
        if (res?.data) {
          handleClose();
          isEmailModal && (!(!newObj?.email)) && modalAlertEmail();
        }
      } catch (e) {
        if(e.message === "Network Error") {    
          setNetWorkError(e.message);
        };    
        setServerError(e.response.data.message); 
      }
    } 

  return (
    <div className="popup-box-settings">      
      <div className={Boolean(errorsList?.password || errorsList?.password2||errorsList?.password3 ) ? "box-settings box-settings-error": "box-settings"}>
        <span className="close-icon-settings" onClick={handleClose}><CrossIcon/></span>
        <h1>
          {getTitle().header}
        </h1>
       {isPasswordModal
          ? <div className="box-settings__password-form">
                <div className='field-container'>
                  <div className="box-settings-input-title">{getTitle()?.inputTitleOld}</div>
                    <input
                      className={Boolean(errorsList?.password) || serverError?.password ? "input_error": "input"}
                      label="password"
                      name="password"
                      type={passwordVisible ? "text" : "password"}   
                      onChange={changeInputRegister}
                      value={register.password}
                      />
                    {
                      <div 
                      className={Boolean(errorsList?.password) || serverError?.password ? "password-image-error password-image-error-modal": "password-image password-image-setting"}
                      onClick={() => setPasswordVisible(prevState => !prevState)}
                      >
                        {
                          passwordVisible
                          ? <OpenedEye/>
                          : <ClosedEye />
                        }
                      </div>
                    }
                    {Boolean(errorsList?.password) ?(<div className="valid-error">{errorsList.password}{serverError}</div>) : null}   
                </div>
                {Boolean(serverError) ? (<div className="valid-error">{serverError}</div>) : null}   
                <div className='field-container'>
                  <div className="box-settings-input-title">{getTitle()?.inputTitleNew}</div>
                  <input
                    className={Boolean(errorsList?.password2) || serverError?.password2 ? "input_error": "input"}
                    label="password2"          
                    name="password2"
                    type={passwordVisible2 ? "text" : "password"}   
                    onChange={changeInputRegister}
                    value={register.password2}      
                    />    
                  {
                    <div
                    className={Boolean(errorsList?.password2) || serverError?.password2 ? "password-image-error  password-image-error-modal": "password-image password-image-setting"}
                    onClick={() => setPasswordVisible2(prevState => !prevState)} 
                    >
                      {
                        passwordVisible2
                          ? <OpenedEye/>
                          : <ClosedEye />
                      }
                    </div>
                  }
                  {Boolean(errorsList?.password2) ? (<div className="valid-error">{errorsList.password2}</div>) : null}         
              </div>

              <div className='field-container'>
                <div className="box-settings-input-title">{getTitle()?.inputTitleRepeat}</div>
                <input
                  className={Boolean(errorsList?.password3) || serverError?.password3 ? "input_error": "input"}
                  label="password3"          
                  name="password3"
                  type={passwordVisible3 ? "text" : "password"}   
                  onChange={changeInputRegister}
                  value={register.password3}      
                  />    
                  {
                    <div               
                    className={Boolean(errorsList?.password3) || serverError?.password3 ? "password-image-error  password3-image-error-modal": "password-image password-image-setting"}
                    onClick={() => setPasswordVisible3(prevState => !prevState)} 
                    >
                      {
                        passwordVisible3
                          ? <OpenedEye/>
                          : <ClosedEye />
                      }
                    </div>
                  }
                {Boolean(errorsList?.password3) ? (<div className="valid-error">{errorsList.password3}</div>) : null}                         
              </div>
            </div>
          : <>
              <div className="box-settings-description">{getTitle()?.description}</div>
              <div className="box-settings-input-title">{getTitle()?.inputTitle}</div>
              <input className="box-settings-input input"  
                type="text"                        
                name={getTitle()?.page}
                onChange={changeInputRegister}              
                value={register[getTitle()?.page]}    
              />
              {Boolean(errorsList) ? (<div className="valid-error">{errorsList[getTitle()?.page]}</div>) : null}   
            </>
       }
        <div className='box-settings-buttons'>
            {
            (field==='Email') ? 
                <button
                  className="form-button "
                  type="submit"
                  onClick={submitCheck}
                >
                  Получить ссылку     
                </button>
            :
                <button
                  className="form-button"
                  type="submit"
                  onClick={submitCheck}
                >
                  Сохранить       
                </button>
            }
            <button
              className="form-button button-cancel-signature"
              type="submit"
              onClick={handleClose}
            >
                 Отменить  
            </button>
        </div>
        {netWorkError && (
          <p className="form-error">
            <span className="valid-error">
              На сервере произошла непредвиденная ошибка. Обратитесь в техническую поддержку.
            </span>
          </p>
        )}     
                {serverError && (
          <p className="form-error">
            <span className="valid-error">
            {serverError}
            </span>
          </p>
        )}     
      </div>
    </div>
  );
};
 
export default ModalSettingUser;