import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.4vw"
      height="1.4vw"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill="#212529"
        fillRule="evenodd"
        d="M20.067 18.433l5.25 5.25a1.128 1.128 0 010 1.634 1.26 1.26 0 01-.816.35 1.26 1.26 0 01-.817-.35l-5.25-5.25c-1.75 1.283-3.85 2.1-6.183 2.1a9.908 9.908 0 01-9.917-9.917 9.908 9.908 0 019.917-9.916 9.908 9.908 0 019.916 9.916c0 2.334-.816 4.55-2.1 6.183zM12.251 4.667a7.568 7.568 0 00-7.584 7.583c0 4.2 3.384 7.584 7.584 7.584a7.49 7.49 0 005.366-2.217 7.49 7.49 0 002.217-5.367c0-4.2-3.383-7.583-7.583-7.583z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0_1030_3552"
        style={{ maskType: "luminance" }}
        width="24"
        height="24"
        x="2"
        y="2"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M20.067 18.433l5.25 5.25a1.128 1.128 0 010 1.634 1.26 1.26 0 01-.816.35 1.26 1.26 0 01-.817-.35l-5.25-5.25c-1.75 1.283-3.85 2.1-6.183 2.1a9.908 9.908 0 01-9.917-9.917 9.908 9.908 0 019.917-9.916 9.908 9.908 0 019.916 9.916c0 2.334-.816 4.55-2.1 6.183zM12.251 4.667a7.568 7.568 0 00-7.584 7.583c0 4.2 3.384 7.584 7.584 7.584a7.49 7.49 0 005.366-2.217 7.49 7.49 0 002.217-5.367c0-4.2-3.383-7.583-7.583-7.583z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_1030_3552)">
        <path
          fill="#212529"
          fillRule="evenodd"
          d="M0 0h28v28H0V0z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
