import React from 'react';

import CrossIcon from '../svgComponents/CrossIcon';

const ModalInviteCounterPartyAlert = ({handleClose}) => {
    return (
        <div className="popup-box-settings">      
        <div className="box-settings">
          <span className="close-icon-settings" onClick={handleClose}><CrossIcon/></span>
            <div className="box-settings-input-title big">
              <p>Ваше приглашение успешно отправлено!</p>
              <p>Вы можете следить за его статусом в разделе «Ожидается ответ»</p>
            </div>
          <div className='box-settings-buttons'>
              <button
                className="form-button"
                type="submit"
                onClick={handleClose}
              >
                  Закрыть
              </button>
          </div>

       
        </div>
      </div>
    );
};

export default ModalInviteCounterPartyAlert;