import React from 'react'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import DeleteIcon from '../svgComponents/DeleteIcon';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '31.91vw',
    height: '21.5vw',
    bgcolor: 'background.paper',
    padding: '2.69vw 3.88vw',
    fontFamily: 'IBM Plex Sans', 
    boxSizing: 'border-box',
    outline: 'none'
  };

export default function ModalSignCompleted({isOpen, setIsOpen}) {
  return (
    <div>
         <Modal
        open={isOpen}
        onClose={()=>setIsOpen(!isOpen)}
      >
        <Box sx={style}>
            <div className='signature-success-close' onClick={() => setIsOpen(!isOpen)}><DeleteIcon /></div>
            <div className='signature-error-text'>Для подписания документа <br />необходимо установить браузерное расширение КриптоПро ЭЦП</div>
            <div className='signature-error-guide'>Инструкция по <a style={{color:"#BDBDBD",textDecoration:'none'}} target="_blank" href="https://docs.cryptopro.ru/cades/plugin/plugin-installation-windows">установке</a></div>
            <div className='signature-success-cancel' onClick={() => setIsOpen(!isOpen)}>Закрыть</div>
        </Box>
      </Modal>
    </div>
  )
}
