import React, {memo} from "react";

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.25vw'
      height='1.25vw'
      fill='none'
      viewBox='0 0 24 24'
    >
      <path
        fill='#BDBDBD'
        d='M18.7 17.3c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3L12 13.4l-5.3 5.3c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l5.3-5.3-5.3-5.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l5.3 5.3 5.3-5.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l5.3 5.3z'
      ></path>
      <mask
        id='mask0_33_56'
        style={{ maskType: "luminance" }}
        width='14'
        height='14'
        x='5'
        y='5'
        maskUnits='userSpaceOnUse'
      >
        <path
          fill='#fff'
          d='M18.7 17.3c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3L12 13.4l-5.3 5.3c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l5.3-5.3-5.3-5.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l5.3 5.3 5.3-5.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l5.3 5.3z'
        ></path>
      </mask>
      <g mask='url(#mask0_33_56)'>
        <path
          fill='#BDBDBD'
          fillRule='evenodd'
          d='M0 0h24v24H0V0z'
          clipRule='evenodd'
        ></path>
      </g>
    </svg>
  );
}

export default memo(Icon);
