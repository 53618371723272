import React, { memo } from 'react';
import SelectButton from './SelectButton';
import globalStyle from '../../DocumentComponents/customStyleButton';
import { TableCell, TableRow } from '@mui/material';

const TableRowUser = ({ changeAccessForSendingDocs, userData }) => {
    return (
        <TableRow hover role="checkbox" tabIndex={-1} key={userData.id}>
            <TableCell
                sx={{
                    ...globalStyle.customStyleTable,
                    width: '32%',
                    wordBreak: 'break-word',
                    fontWeight: '400',
                }}
            >
                {userData.name}
            </TableCell>
            <TableCell
                sx={{
                    ...globalStyle.customStyleTable,
                    width: '29.3%',
                    wordBreak: 'break-word',
                    fontWeight: '400',
                }}
            >
                {userData.id}
            </TableCell>
            <TableCell
                sx={{
                    ...globalStyle.customStyleTable,
                    width: '12.7%',
                    wordBreak: 'break-word',
                    fontWeight: '400',
                }}
                onClick={() => changeAccessForSendingDocs(userData.id)}
            >
                {userData.identification_number}
            </TableCell>
            <TableCell
                sx={{
                    ...globalStyle.customStyleTable,
                    width: '14.4%',
                    wordBreak: 'break-word',
                    fontWeight: '400',
                }}
            >
                {userData.kpp}
            </TableCell>
            <TableCell
                sx={{
                    ...globalStyle.customStyleTable,
                    width: '13%',
                    paddingLeft: '0',
                }}
            >
                <SelectButton changeAccessForSendingDocs={changeAccessForSendingDocs} userId={userData.id} isBlocked={userData.is_blocked} />
            </TableCell>
        </TableRow>
    );
};

export default memo(TableRowUser);
