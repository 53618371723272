import React, { createContext, useEffect, useState } from 'react';

export const UserContext = createContext();

export const UserContextProvider = (props) => {
  const { children, user: initialUser, isAdmin } = props || {};

  useEffect(() => {
    setUser(initialUser);
  }, [initialUser]);

  const [user, setUser] = useState(() => ({ ...initialUser }));
  const [isFirstLogin, setIsFirstLogin] = useState(false);
  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        isFirstLogin,
        setIsFirstLogin,
        isAdmin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
