import { Box, Tab, TablePagination, Tabs, Typography, styled } from "@mui/material";

export const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{
            children: (
                <span
                    style={{
                        width: props.isNewTab || props.allAdmin ? '65%' : '80%',
                        background: '#8AB528',
                        zIndex: 503,
                        transform: props.allAdmin ? 'translateX(-12px)' : null,
                    }}
                    className="MuiTabs-indicatorSpan"
                />
            ),
        }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        width: '4.9vw',
        height: '0.52vw',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        maxWidth: '100%',
        zIndex: 504,
        marginLeft: '-1.5vw',
        transition: 'none',
    },
});

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    height: '5.2vw',
    fontSize: '1.25vw',
    color: '#181818',
    paddingLeft: 0,
    paddingRight: '3vw',
    '&.Mui-selected': {
        color: '#181818',
        fontWeight: '700',
    },
    '&.Mui-focusVisible': {
        backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
}));

const customStyleUndo = {
    marginTop: '1.04vw',
};
const customStyleUpper = {
    marginTop: '1.04vw',
    paddingBottom: '6.5vw',
};

let rowsWait = [];

export const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && (
                <Box sx={!rowsWait.length ? customStyleUndo : customStyleUpper}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

export const StyledPagination = styled(TablePagination)(({ theme }) => ({
    root: {},
    '&.MuiTablePagination-root': {
        justifyContent: 'space-between',
        display: 'flex',
        width: '100%',
    },
    '.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
    '.MuiTablePagination-spacer': {
        display: 'none',
    },
    '.MuiInputBase-root': {
        marginRight: 'auto',
    },
    '.MuiSelect-iconStandard': {},
}));
