import React, { useContext } from 'react';

import { TabsContext } from '../../helpers/TabsContext';
import Lupa from '../svgComponents/Lupa';

const NoWaitCounterParty = () => {
    const {
        currentTab,
        currentPageTab,
        setCurrentTab,
        setCurrentPageTab
    } = useContext(TabsContext);
    
    const handleClick = () => {
        setCurrentTab(1);
        setCurrentPageTab(0)
    }
    return (
        <div className='no-document no-wait'>
            <Lupa/>
            <h1>Нет отправленных приглашений</h1>
            <p>На этой странице будут показаны организации, которым вы <br/>
            отправили приглашения и ожидаете от них ответ.
            </p>
            <button
                className="form-button"
                type="submit"
               onClick={handleClick}
            >
            Отправить приглашение         
            </button>
        </div>
    );
};

export default NoWaitCounterParty;