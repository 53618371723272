import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.3vw"
      height="8.9vw"
      fill="none"
      viewBox="0 0 160 172"
    >
      <path
        fill="#FEA000"
        d="M56.95 148.247c4.534 0 8.21-3.691 8.21-8.244 0-4.554-3.675-8.245-8.21-8.245-4.533 0-8.208 3.691-8.208 8.245 0 4.553 3.675 8.244 8.209 8.244zM74.167 149.452c2.597 0 4.703-2.116 4.703-4.725 0-2.609-2.106-4.724-4.703-4.724-2.598 0-4.704 2.115-4.704 4.724s2.106 4.725 4.704 4.725zM106.854 74.984h-.039l-6.244-.254a.939.939 0 01-.899-.98.945.945 0 01.976-.902l6.245.254a.941.941 0 01-.039 1.882zm-12.49-.506l-.04-.001-6.244-.254a.942.942 0 01.076-1.882l6.246.254c.517.02.92.46.898.98a.94.94 0 01-.936.903zm-12.49-.507l-.039-.002-6.245-.253a.94.94 0 01-.898-.979c.02-.52.46-.919.975-.903l6.244.254c.517.02.92.459.899.98a.939.939 0 01-.936.903zm-12.49-.508h-.038l-6.245-.254a.943.943 0 01.076-1.882l6.245.254c.518.02.92.458.899.978a.94.94 0 01-.936.904zm-12.49-.507h-.038l-4.78-.195a.94.94 0 01-.9-.979.943.943 0 01.976-.902l4.78.194a.94.94 0 01.899.979.94.94 0 01-.936.903zM54.34 86.582a.938.938 0 01-.935-.916.94.94 0 01.911-.967l1.83-.05a.935.935 0 01.961.916.94.94 0 01-.912.967l-1.828.05h-.026zm8.077-.219a.939.939 0 01-.936-.916.94.94 0 01.912-.967l6.247-.17a.939.939 0 01.962.916.94.94 0 01-.912.967l-6.247.17h-.026zm12.496-.338a.939.939 0 01-.936-.917.94.94 0 01.911-.966l6.248-.17a.937.937 0 01.962.916.94.94 0 01-.912.967l-6.248.17h-.025zm12.496-.339a.938.938 0 01-.936-.916.94.94 0 01.912-.967l6.247-.17a.935.935 0 01.962.917.94.94 0 01-.912.966l-6.247.17h-.026zm12.495-.338a.939.939 0 01-.936-.916.94.94 0 01.912-.966l6.247-.17a.938.938 0 01.962.916.94.94 0 01-.912.967l-6.247.17h-.026z"
      ></path>
      <path
        fill="#000"
        d="M127.584 149.657a.935.935 0 01-.795-.441c-9.754-15.594-37.396-56.064-48.007-56.064-6.886 0-30.042 32.132-43.926 53.728a.935.935 0 01-1.296.28.944.944 0 01-.279-1.302c3.592-5.586 35.385-54.59 45.502-54.59 13.814 0 48.141 54.621 49.596 56.946a.944.944 0 01-.795 1.443zM128.749 79.846a.942.942 0 01-.928-1.085l8.76-57.287-112.313-3.472c1.623 6.104 8.39 33.559 5.934 58.28a.94.94 0 01-1.026.844.94.94 0 01-.84-1.03c2.731-27.5-6.119-58.502-6.209-58.812a.945.945 0 01.16-.84.953.953 0 01.769-.364l114.639 3.545c.27.008.521.131.692.338a.948.948 0 01.206.746l-8.92 58.338a.935.935 0 01-.924.799z"
      ></path>
      <path
        fill="#8AB528"
        d="M119.209 66.54l-78.784-1.073-1.708-33.453h81.774l-1.282 34.525z"
      ></path>
      <path
        fill="#000"
        d="M62.514 100.4a.928.928 0 01-.535-.169l-39.487-27.64a.944.944 0 01-.234-1.31.934.934 0 011.305-.235l39.488 27.64a.944.944 0 01-.537 1.714zM94.669 104.71a.936.936 0 01-.76-.387.947.947 0 01.208-1.317l46.82-34.16a.936.936 0 011.31.208.945.945 0 01-.208 1.316l-46.82 34.161a.937.937 0 01-.55.179zM75.889 18.832l5.529-5.683 5.91 5.683h2.71l-7.995-7.688a.933.933 0 00-1.318.022l-7.457 7.666h2.62z"
      ></path>
      <path
        fill="#000"
        d="M142.425 69.585a.941.941 0 00-.288-.656l-10.678-10.267v2.605l9.103 8.753c.666 26.615-.255 78.098-8.483 85.009-5.012 4.208-25.689 6.166-53.967 5.109-24.675-.923-47.035-4.045-49.845-6.96-10.2-10.579-4.79-75.682-4.334-80.94l5.442-5.594v-2.695l-7.018 7.215a.949.949 0 00-.263.572c-.264 2.9-6.343 71.167 4.825 82.752 3.69 3.829 28.511 6.687 51.123 7.533 3.08.115 8.171.262 14.08.262 15.048 0 35.378-.954 41.158-5.808 11.022-9.256 9.228-83.725 9.145-86.89z"
      ></path>
    </svg>
  );
}

export default Icon;
