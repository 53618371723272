import React, {useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import IconButton from '@mui/material/IconButton';

import { removeToken } from '../../helpers';
import {UserContext } from '../../helpers/UserContext';
import {TabsContext} from "../../helpers/TabsContext";
import api from '../../api/api';
import HeaderIcon from '../svgComponents/HeaderIcon';
import CancelIcon from '../svgComponents/DeleteIcon';
import CopyIconOpacity from '../svgComponents/CopyIconOpacity';
import SettingsIcon from '../svgComponents/SettingsIcon';
import LogoutIcon from '../svgComponents/LogoutIcon';


const SETTING_TAB_INDEX = 2; 


const HeaderContent = () => {
    const { user,setUser } = useContext(UserContext) 
    const {currentTab, setCurrentTab} = useContext(TabsContext);

    const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
    const isDocumentTabActive = currentTab === 0;
    const navigate = useNavigate();

    useEffect(() => {
        const findUser = async () => {
            const res = await api.get(`/find-user`);
            if (res?.data) {
            setUser(res?.data);
            };
        };
        void findUser();
    }, [])

    const handleSettingsClick = (index) => {
        setCurrentTab(index);
        setIsUserMenuOpen(!isUserMenuOpen);
    };
    const handleModalClick = () => {
        setIsUserMenuOpen(!isUserMenuOpen);
    };
    const handleLogoutClick = async () => {
        await api.post('auth/logout');
        removeToken();
        navigate('/login');
    }; 

    return (
        <>
            <div 
                className={`dark-background ${isUserMenuOpen ? 'show' : ''}`}
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    backgroundColor: 'rgba(0,0,0,0.2)'
                }}
                onClick={() => setIsUserMenuOpen(false)}
            ></div>
            <div
                className={`description-header ${!isUserMenuOpen ? 'show' : ''}`}
            >
                <div 
                    className='main'     
                    
                >
                    <div className='main__header-content' onClick={() => handleModalClick()}>
                        <HeaderIcon className='description-header_header-icon'/>
                        <div style={{wordBreak: 'break-all',textAlign:'left'}}>
                            {user.name}
                        </div>
                    </div>
                   
                </div>
            </div>
            <div 
                className={`description-header ${isUserMenuOpen ? 'show' : ''}`} 
            >
                <div className={`
                    description-header__user-menu user-menu
                
                `}>
                    <IconButton 
                        type='button'
                        onClick={() => handleModalClick()}
                        className='user-menu__close-button button'
                        style={{
                            backgroundColor: 'transparent',
                            padding: '0',
                            marginBottom: 'calc(30/1920*100vw)',
                            width: 'calc(24/1920*100vw)',
                            height: 'calc(24/1920*100vw)',
                        }}
                    >
                        <CancelIcon />
                    </IconButton>
                    <div className='user-menu__name'>{user.name}</div>
                    <div className='user-menu__user-id user-id'>
                        <span className='user-id__label'>ID SIMPSIGN</span>
                        <span className='user-id__content'>
                            <p>{user.id}</p>
                            <IconButton 
                                type='button'
                                className='user-id__copy-button button'
                                style={{ backgroundColor: 'transparent' }}
                                disableRipple
                                onClick={() => navigator.clipboard.writeText(user?.id)}
                            >
                                <CopyIconOpacity/>
                            </IconButton>
                        </span>
                    </div>
                    <button 
                        className='user-menu__setting link'
                        onClick={() => {handleSettingsClick(SETTING_TAB_INDEX)}}
                    >
                        <SettingsIcon />
                        <span>Настройки</span>
                    </button>
                    <button
                        className='user-menu__logout link'
                        onClick={() => {handleLogoutClick()}}    
                    >
                        <LogoutIcon />
                        <span>Выход</span>
                    </button>
                </div>
            </div>
        </>
    );
};

export default HeaderContent;