import React from 'react';
import {useNavigate,useLocation} from "react-router-dom";
import { Formik, Form,} from 'formik';

import Header from '../component/Headers/Header';

const EmailSend = () => {
    const navigate = useNavigate();  
    const location = useLocation(); 
    const submitCheck = event => {
        event.preventDefault();
        navigate('/')
    }
    return (
             <>
                <Header/>
                <Formik>                    
                    <div className='form-wrapper'>
                        <Form className='form-container'>
                            <h1 className='form-title'>Восстановить пароль</h1>
                            <h6 className='form-subtitle'>
                                На {location.state.email} было отправлено письмо для восстановления пароля.<br/>
                                Следуйте инструкциям в письме для входа в сервис
                            </h6>  
                            <div className="form-buttons-container">
                                <button
                                    className="form-button"
                                    type="submit"
                                    onClick={submitCheck}
                                >
                                Вернуться ко входу в сервис                                  
                                </button>            
                            </div>              
                        </Form>                     
                        <div className='footer' style={{marginTop:'22vw'}}>
                            <div className='footer__register'>
                                <p>Узнать больше о сервисе SimpSign</p>
                                <a href='https://simpsign.com/' target='blank'>www.simpsign.com</a>
                            </div>
                        </div>       
                    </div>                                     
                </Formik>             
            </>
    );
};

export default EmailSend;