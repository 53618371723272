import React from "react";

function Icon(prop) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.6vw"
      height="2.6vw"
      fill="none"
      viewBox="0 0 50 50"
      {...prop}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8.367 14.584c0-5.834 4.601-10.417 10.458-10.417 5.856 0 10.458 4.583 10.458 10.417C29.283 20.417 24.68 25 18.825 25c-5.857 0-10.458-4.583-10.458-10.416zm29.283 25v4.166c0 1.25-.837 2.084-2.092 2.084-1.255 0-2.092-.834-2.092-2.084v-4.166c0-3.542-2.719-6.25-6.275-6.25H10.458c-3.556 0-6.275 2.708-6.275 6.25v4.166c0 1.25-.836 2.084-2.091 2.084C.837 45.834 0 45 0 43.75v-4.166c0-5.834 4.602-10.417 10.458-10.417h16.733c5.857 0 10.459 4.583 10.459 10.417zm-18.825-18.75c-3.556 0-6.275-2.709-6.275-6.25 0-3.542 2.719-6.25 6.275-6.25 3.556 0 6.275 2.708 6.275 6.25 0 3.541-2.72 6.25-6.275 6.25zm23.426 8.75c-1.046-.209-2.3.416-2.51 1.458-.209 1.042.418 2.292 1.464 2.5 2.72.625 4.602 3.125 4.602 6.042v4.166c0 1.25.837 2.084 2.091 2.084 1.255 0 2.092-.834 2.092-2.084v-4.166c.21-4.792-2.928-8.959-7.739-10zM31.375 6.042c.418-1.25 1.464-1.875 2.51-1.458 5.647 1.25 8.994 7.083 7.53 12.916-.837 3.75-3.765 6.459-7.53 7.5h-.419c-1.046 0-1.882-.625-2.091-1.666-.419-1.042.418-2.292 1.464-2.5 2.3-.625 3.974-2.292 4.601-4.584.837-3.541-1.255-6.875-4.601-7.708-1.046-.208-1.674-1.458-1.464-2.5z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0_1056_2821"
        style={{ maskType: "luminance" }}
        width="50"
        height="42"
        x="0"
        y="4"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M8.367 14.584c0-5.834 4.601-10.417 10.458-10.417 5.856 0 10.458 4.583 10.458 10.417C29.283 20.417 24.68 25 18.825 25c-5.857 0-10.458-4.583-10.458-10.416zm29.283 25v4.166c0 1.25-.837 2.084-2.092 2.084-1.255 0-2.092-.834-2.092-2.084v-4.166c0-3.542-2.719-6.25-6.275-6.25H10.458c-3.556 0-6.275 2.708-6.275 6.25v4.166c0 1.25-.836 2.084-2.091 2.084C.837 45.834 0 45 0 43.75v-4.166c0-5.834 4.602-10.417 10.458-10.417h16.733c5.857 0 10.459 4.583 10.459 10.417zm-18.825-18.75c-3.556 0-6.275-2.709-6.275-6.25 0-3.542 2.719-6.25 6.275-6.25 3.556 0 6.275 2.708 6.275 6.25 0 3.541-2.72 6.25-6.275 6.25zm23.426 8.75c-1.046-.209-2.3.416-2.51 1.458-.209 1.042.418 2.292 1.464 2.5 2.72.625 4.602 3.125 4.602 6.042v4.166c0 1.25.837 2.084 2.091 2.084 1.255 0 2.092-.834 2.092-2.084v-4.166c.21-4.792-2.928-8.959-7.739-10zM31.375 6.042c.418-1.25 1.464-1.875 2.51-1.458 5.647 1.25 8.994 7.083 7.53 12.916-.837 3.75-3.765 6.459-7.53 7.5h-.419c-1.046 0-1.882-.625-2.091-1.666-.419-1.042.418-2.292 1.464-2.5 2.3-.625 3.974-2.292 4.601-4.584.837-3.541-1.255-6.875-4.601-7.708-1.046-.208-1.674-1.458-1.464-2.5z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_1056_2821)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M0 0h50v50H0V0z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
