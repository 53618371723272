import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.4vw"
      height="1.5vw"
      fill="none"
      viewBox="0 0 28 24"
      {...props}
    >
      <path
        fill="#212529"
        fillRule="evenodd"
        d="M8.167 8.667c0-3.267 2.566-5.834 5.833-5.834 3.267 0 5.833 2.567 5.833 5.834 0 3.266-2.566 5.833-5.833 5.833-3.267 0-5.833-2.567-5.833-5.833zm16.333 14V25c0 .7-.467 1.167-1.167 1.167S22.167 25.7 22.167 25v-2.333c0-1.984-1.517-3.5-3.5-3.5H9.333c-1.983 0-3.5 1.516-3.5 3.5V25c0 .7-.466 1.167-1.166 1.167S3.5 25.7 3.5 25v-2.333c0-3.267 2.567-5.834 5.833-5.834h9.334c3.266 0 5.833 2.567 5.833 5.834zM14 12.167c-1.983 0-3.5-1.517-3.5-3.5 0-1.984 1.517-3.5 3.5-3.5s3.5 1.516 3.5 3.5c0 1.983-1.517 3.5-3.5 3.5z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0_973_3311"
        style={{ maskType: "luminance" }}
        width="22"
        height="24"
        x="3"
        y="2"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M8.167 8.667c0-3.267 2.566-5.834 5.833-5.834 3.267 0 5.833 2.567 5.833 5.834 0 3.266-2.566 5.833-5.833 5.833-3.267 0-5.833-2.567-5.833-5.833zm16.333 14V25c0 .7-.467 1.167-1.167 1.167S22.167 25.7 22.167 25v-2.333c0-1.984-1.517-3.5-3.5-3.5H9.333c-1.983 0-3.5 1.516-3.5 3.5V25c0 .7-.466 1.167-1.166 1.167S3.5 25.7 3.5 25v-2.333c0-3.267 2.567-5.834 5.833-5.834h9.334c3.266 0 5.833 2.567 5.833 5.834zM14 12.167c-1.983 0-3.5-1.517-3.5-3.5 0-1.984 1.517-3.5 3.5-3.5s3.5 1.516 3.5 3.5c0 1.983-1.517 3.5-3.5 3.5z"
          clipRule="evenodd"
        ></path>
      </mask>
    </svg>
  );
}

export default Icon;
