import React, { useState } from 'react';
import {Link,useNavigate,useLocation} from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import validator from 'validator';

import api from "../api/api";
import Header from "../component/Headers/Header";
import ClosedEye from '../component/svgComponents/ClosedEye';
import OpenedEye from '../component/svgComponents/OpenedEye';

const MESSAGE_PASSWORD = "Пароль должен состоять из одной строчной, прописной буквы и цифры, не менее 8 символов";
const MESSAGE_PASSWORDS_MATCH = "Пароли должны совпадать";
const MESSAGE_NOT_SUCCESS_USER = "Не удалось подтвердить личность, повторите пожалуйста процесс востановления пароля";



export default function RegisterPage () {
    const navigate = useNavigate();
    const [netWorkError,setNetWorkError] = useState()
    const [serverError,setServerError] = useState();
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [passwordVisible2, setPasswordVisible2] = useState(false);
    const search = useLocation().search;
    const code = new URLSearchParams(search).get('code');
    const email = new URLSearchParams(search).get('email');  

    const initialErrorsList = {
        password: "",   
        password2:"",         
      }
   
    const [errorsList, setErrorsList] = useState(initialErrorsList)

    const [register, setRegister] = useState(() => {
        return {
            email: "",
            password: "",
            password2: "",
        }
    })
     
    const removeFieldError = (field) => {
        setErrorsList(prevState => ({...prevState, [`${field}`]: ''}))
    } 

    const changeInputRegister = event => {   
        const changedValue = event.target.value
        const fieldName = event.target.name
        removeFieldError(fieldName);
        setRegister(prev => {
            return {
                ...prev,
                [fieldName]: changedValue,
            }
        })
    }

    const submitCheck = async (event) => {
      event.preventDefault();
      let wasError = false;

      const {

        password,
        password2
      } = register;


      if (!validator.isStrongPassword(password, {minSymbols: 0})) {
        setErrorsList(prevState => ({...prevState, password: MESSAGE_PASSWORD}))
        wasError = true;
      }
      if (password2 !== password) {
        setErrorsList(prevState => ({...prevState, password2: MESSAGE_PASSWORDS_MATCH}))
        wasError = true;
      }
      if  (!code) {
        setErrorsList(prevState => ({...prevState, password1: MESSAGE_NOT_SUCCESS_USER}))
        wasError = true;
      }

      if (wasError) {
        return;
      }
      try {
        const res = await api.post(
          "/auth/reset-password/", {
           code : code,
           email: email,
           password: register.password,
        });
        if (res?.data) {
          navigate('/login?successPassword');
        }
      } catch (e) {
        if(e.message === "Network Error") {          
          setNetWorkError(e.message);
        };
        setServerError(e.response.data.message);     
      };
    }

    return (
        <> 
        <Header/>
          <Formik            
          initialValues={register}
          onSubmit={submitCheck}
        >          
          <div className='form-wrapper'>
          <Form className='form-container container-login'>
                 <h1 className='form-title'>Введите новый пароль</h1>
                 <div className='field-container'>
                <p className="field-title">Пароль</p>
                  <Field
                    className={Boolean(errorsList?.password) || serverError?.password ? "input_error": "input"}
                    label="password"
                    name="password"
                    type={passwordVisible ? "text" : "password"}   
                    onChange={changeInputRegister}
                    value={register.password}     
                  />
                  {
                    <div 
                    className={Boolean(errorsList?.password) || serverError?.password ? "password-image-error password-image-error-register": "password-image"}
                    onClick={() => setPasswordVisible(prevState => !prevState)}
                    >
                      {
                        passwordVisible
                          ? <OpenedEye/>
                          : <ClosedEye />
                      }
                    </div>
                  }
                  {Boolean(errorsList?.password) ? (<div className="valid-error">{errorsList.password}</div>) : null}  
                  
              </div>
            

            <div className='field-container'>
              <p className="field-title">Повторить пароль</p>
              <Field
                className={Boolean(errorsList?.password2) || serverError?.password2 ? "input_error": "input"}
                label="password2"          
                name="password2"
                type={passwordVisible2 ? "text" : "password"}   
                onChange={changeInputRegister}
                value={register.password2}      
                />    
                {
                  <div
                  className={Boolean(errorsList?.password2) || serverError?.password2 ? "password-image-error  password2-image-error-register": "password-image"}
                  onClick={() => setPasswordVisible2(prevState => !prevState)} 
                  >
                    {
                      passwordVisible2
                        ? <OpenedEye/>
                        : <ClosedEye />
                    }
                  </div>
                }
              {Boolean(errorsList?.password2) ? (<div className="valid-error">{errorsList.password2}</div>) : null}  
                              
            </div>          
              <div className="form-buttons-container">
                <button
                  className="form-button button-login"
                  type="submit"
                 onClick={submitCheck}
                >
                 Обновить пароль           
                </button>
  
              </div>
              <div className='forget-password-links'> <Link className='form__link' to="/login"><span>Войти</span></Link> или <Link className='form__link' to="/">Зарегистрироваться</Link></div> 
           </Form>
              <p className="form-error">
              {(<p className="valid-error">{serverError ? serverError:""}</p>)} 
                 { netWorkError ? <span className="valid-error">На сервере произошла непредвиденная ошибка. Обратитесь в техническую поддержку.</span> :""}
               </p>
             
            <div className='footer' style={{marginTop:'10vw'}}>
              <div className='footer__register'>
              <p>Узнать больше о сервисе SimpSign</p>
              <a href='https://simpsign.com/' target='blank'>www.simpsign.com</a>
            </div>
        </div>       
        </div>                     
        </Formik>
        <div>
    </div>
        </>
    )
    
}
