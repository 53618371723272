import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.25vw"
      height="1.3vw"
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        fill="#949DAF"
        fillRule="evenodd"
        d="M18.207 4.707a1.121 1.121 0 011.586 1.586L7.489 18.597l-2.115.529.529-2.115L18.207 4.707zM19 2.38c-.828 0-1.622.329-2.207.914l-12.5 12.5a1 1 0 00-.263.464l-1 4a1 1 0 001.213 1.213l4-1a1 1 0 00.464-.263l12.5-12.5a3.12 3.12 0 00-1.012-5.09A3.121 3.121 0 0019 2.378zM13 19.5a1 1 0 100 2h9a1 1 0 100-2h-9z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default Icon;
