import React, {createContext, useState} from "react";

const initialState = {
    currentTab: 0,
    currentPageTab: 1,
    setCurrentTab: (number) => {},
    setCurrentPageTab: (number) => {},
}

export const TabsContext = createContext(initialState);

export const TabsContextProvider = ({children}) => {
    
    const [currentPageTab, setCurrentPageTab] = useState(0)
    const [currentTab, setCurrentTab] = useState(1)

    return (
        <TabsContext.Provider value={{
            currentPageTab,
            setCurrentPageTab,
            currentTab,
            setCurrentTab,
        }}>
            {children}
        </TabsContext.Provider>
    )
}

export const navigateTabFromLocalStorage = (setCurrentTab, setCurrentPageTab) => {
    const tabStringified = localStorage.getItem('Tab');
    if (!tabStringified) return;

    const chosenTabs = JSON.parse(tabStringified);

    const currentTab = chosenTabs?.currentTab;
    const currentPageTab = chosenTabs?.currentPageTab;

    setCurrentTab(currentTab);
    setCurrentPageTab(currentPageTab);
}