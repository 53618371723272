import dayjs from 'dayjs';

const checkExpirationDateOfCertificateFromFiles = (recipient, sender, isExpired) => {
    const currentDate = dayjs();

    if (recipient) {
        let recipientDate = recipient[0].validTo.split('.');
        recipientDate = recipientDate[1] + '.' + recipientDate[0] + '.' + recipientDate[2];

        const expirationDateCertificateByRecipient = dayjs(recipientDate);
        const diffDaysOfRecipient = expirationDateCertificateByRecipient.diff(currentDate, 'days');

        isExpired = diffDaysOfRecipient <= 0;

        if (isExpired) {
            return isExpired;
        }
    }

    if (sender) {
        let senderDate = sender[0].validTo.split('.');
        senderDate = senderDate[1] + '.' + senderDate[0] + '.' + senderDate[2];

        const expirationDateCertificateBySender = dayjs(senderDate);
        const diffDaysOfSender = expirationDateCertificateBySender.diff(currentDate, 'days');

        isExpired = diffDaysOfSender <= 0;

        if (isExpired) {
            return isExpired;
        }
    }

    return isExpired;
};

export default checkExpirationDateOfCertificateFromFiles;
