import React from 'react';

import { IconButton } from '@mui/material';

import CopyIconWorkflowID from '../svgComponents/CopyIconWorkflowID'

const WorkflowID = ({workflowId}) => {
    return (
        <div className='workflow'>
            <span className='workflow__id'>ID: </span>
            <span className='workflow__value'>
             {workflowId}            
            </span>
            <IconButton 
                type='button'
                sx={{p:0}}  
                style={{ backgroundColor: 'transparent',alignSelf: 'self-start' }}
                disableRipple
                onClick={() => navigator.clipboard.writeText(workflowId)}
            >
                <CopyIconWorkflowID/>
            </IconButton>
        </div>
    );
};

export default WorkflowID;