import React from 'react';

const Checkbox = (props) => {
    const {checked} = props;
    return (
        checked
            ? <svg width="1.25vw" height="1.25vw" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="#8AB528"/>
                <path d="M20.7 7.7L9.7 18.7C9.5 18.9 9.3 19 9 19C8.7 19 8.5 18.9 8.3 18.7L3.3 13.7C2.9 13.3 2.9 12.7 3.3 12.3C3.7 11.9 4.3 11.9 4.7 12.3L9 16.6L19.3 6.3C19.7 5.9 20.3 5.9 20.7 6.3C21.1 6.7 21.1 7.3 20.7 7.7Z" fill="#212529"/>
                <mask id="mask0_230_890"  maskUnits="userSpaceOnUse" x="3" y="6" width="18" height="13">
                    <path d="M20.7 7.7L9.7 18.7C9.5 18.9 9.3 19 9 19C8.7 19 8.5 18.9 8.3 18.7L3.3 13.7C2.9 13.3 2.9 12.7 3.3 12.3C3.7 11.9 4.3 11.9 4.7 12.3L9 16.6L19.3 6.3C19.7 5.9 20.3 5.9 20.7 6.3C21.1 6.7 21.1 7.3 20.7 7.7Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_230_890)">
                    <rect width="24" height="24" fill="white"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H24V24H0V0Z" fill="white"/>
                </g>
                <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#8AB528"/>
            </svg>
            : <svg width="1.25vw" height="1.25vw" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white"/>
                <path d="M20.7 7.7L9.7 18.7C9.5 18.9 9.3 19 9 19C8.7 19 8.5 18.9 8.3 18.7L3.3 13.7C2.9 13.3 2.9 12.7 3.3 12.3C3.7 11.9 4.3 11.9 4.7 12.3L9 16.6L19.3 6.3C19.7 5.9 20.3 5.9 20.7 6.3C21.1 6.7 21.1 7.3 20.7 7.7Z" fill="white"/>
                <mask id="mask0_230_885"  maskUnits="userSpaceOnUse" x="3" y="6" width="18" height="13">
                    <path d="M20.7 7.7L9.7 18.7C9.5 18.9 9.3 19 9 19C8.7 19 8.5 18.9 8.3 18.7L3.3 13.7C2.9 13.3 2.9 12.7 3.3 12.3C3.7 11.9 4.3 11.9 4.7 12.3L9 16.6L19.3 6.3C19.7 5.9 20.3 5.9 20.7 6.3C21.1 6.7 21.1 7.3 20.7 7.7Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_230_885)">
                </g>
                <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke="#BDBDBD"/>
            </svg>
    );
};

export default Checkbox;