import React, { useState } from "react";
import TableRowUser from "./TableRowUser";
import api from "../../../api/api";
import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import { StyledPagination } from "./StyledComponents";

const TableUsers = ({ users, changeAccessForSendingDocs }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(30);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <Paper
            sx={{
                width: 'calc(1600/1920*100vw)',
                overflow: 'hidden',
                padding: '0',
                borderRadius: 0,
            }}
        >
            <TableContainer sx={{ maxHeight: 'auto', position: 'sticky' }}>
                <Table aria-label="sticky table" stickyHeader={true}>
                    <TableBody>
                        {users?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => {
                            return <TableRowUser changeAccessForSendingDocs={changeAccessForSendingDocs} userData={user} />;
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <StyledPagination
                labelRowsPerPage="На странице"
                rowsPerPageOptions={[30, 50, 100]}
                sx={{
                    width: '100%',
                    justifyContent: 'space-between',
                    fontSize: '0.7vw',
                }}
                component="div"
                count={users.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelDisplayedRows={({ from, to, count }) => {
                    return `${Math.ceil(to / rowsPerPage)} из ${Math.ceil(count / rowsPerPage)} страниц `;
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </Paper>
    );
};

export default TableUsers;
