import React from "react";

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2.6vw"
      height="2.6vw"
      fill="none"
      viewBox="0 0 50 50"
      {...props}
    >
      <path
        fill="#212529"
        fillRule="evenodd"
        d="M43.333 15.52c.052.105.105.209.209.313.208.208.208.625.208.833v25c0 3.542-2.708 6.25-6.25 6.25h-25c-3.542 0-6.25-2.708-6.25-6.25V8.333c0-3.542 2.708-6.25 6.25-6.25h16.667c.208 0 .625 0 .833.208.208 0 .417.209.625.417l12.5 12.5c.104.104.156.208.208.313zm-6.666-.937L31.25 9.166v5.417h5.417zM37.5 43.75h-25c-1.25 0-2.083-.834-2.083-2.084V8.333c0-1.25.833-2.083 2.083-2.083h14.583v10.416c0 1.25.834 2.084 2.084 2.084h10.416v22.916c0 1.25-.833 2.084-2.083 2.084zm-4.167-14.584c1.25 0 2.084-.833 2.084-2.083 0-1.25-.834-2.083-2.084-2.083H16.667c-1.25 0-2.084.833-2.084 2.083 0 1.25.834 2.083 2.084 2.083h16.666zm2.084 6.25c0 1.25-.834 2.084-2.084 2.084H16.667c-1.25 0-2.084-.834-2.084-2.084s.834-2.083 2.084-2.083h16.666c1.25 0 2.084.833 2.084 2.083zm-18.75-18.75c-1.25 0-2.084.834-2.084 2.084s.834 2.083 2.084 2.083h4.166c1.25 0 2.084-.833 2.084-2.083 0-1.25-.834-2.084-2.084-2.084h-4.166z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0_1056_2799"
        style={{ maskType: "luminance" }}
        width="38"
        height="46"
        x="6"
        y="2"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M43.333 15.52c.052.105.105.209.209.313.208.208.208.625.208.833v25c0 3.542-2.708 6.25-6.25 6.25h-25c-3.542 0-6.25-2.708-6.25-6.25V8.333c0-3.542 2.708-6.25 6.25-6.25h16.667c.208 0 .625 0 .833.208.208 0 .417.209.625.417l12.5 12.5c.104.104.156.208.208.313zm-6.666-.937L31.25 9.166v5.417h5.417zM37.5 43.75h-25c-1.25 0-2.083-.834-2.083-2.084V8.333c0-1.25.833-2.083 2.083-2.083h14.583v10.416c0 1.25.834 2.084 2.084 2.084h10.416v22.916c0 1.25-.833 2.084-2.083 2.084zm-4.167-14.584c1.25 0 2.084-.833 2.084-2.083 0-1.25-.834-2.083-2.084-2.083H16.667c-1.25 0-2.084.833-2.084 2.083 0 1.25.834 2.083 2.084 2.083h16.666zm2.084 6.25c0 1.25-.834 2.084-2.084 2.084H16.667c-1.25 0-2.084-.834-2.084-2.084s.834-2.083 2.084-2.083h16.666c1.25 0 2.084.833 2.084 2.083zm-18.75-18.75c-1.25 0-2.084.834-2.084 2.084s.834 2.083 2.084 2.083h4.166c1.25 0 2.084-.833 2.084-2.083 0-1.25-.834-2.084-2.084-2.084h-4.166z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_1056_2799)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M-5 0h60v60H-5V0z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;