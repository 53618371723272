import React, {useContext} from 'react'

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { TabsContext } from '../../helpers/TabsContext';
import DeleteIcon from '../svgComponents/DeleteIcon';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '31.91vw',
    height: '21.5vw',
    bgcolor: 'background.paper',
    padding: '2.69vw 3.88vw',
    fontFamily: 'IBM Plex Sans', 
    boxSizing: 'border-box',
    outline: 'none'
  };

export default function ModalSignSending({isOpen, setIsOpen, sendError}) {
  const {currentPageTab} = useContext(TabsContext);
  return (
    <div>
         <Modal
        open={isOpen}
        onClose={()=>setIsOpen(!isOpen)}
      >
        <Box sx={style}>
          {(sendError) ? 
            <>
              <div className='signature-success-close' onClick={() => setIsOpen(!isOpen)}><DeleteIcon /></div>
              <div className='signature-success-text'>
                  <div>Не удалось подписать и отправить <br /> пакет документов</div>
                  <div className='signature-succes-text__lower'>Попробуйте снова</div>
              </div>
              <div className='signature-success-cancel' onClick={() => setIsOpen(!isOpen)}>Закрыть</div>
            </>
            :
            <>
            <div className='signature-success-close' onClick={() => setIsOpen(!isOpen)}><DeleteIcon /></div>
            {currentPageTab 
            ? 
            <div className='signature-success-text'>
              <div>Выбранный пакет документов <br /> успешно подписан</div>
              <div className='signature-succes-text__lower'>Начат процесс валидации <br /> подписи</div>
            </div>
            :
              <div className='signature-success-text'>
                  <div>Ваш пакет документов успешно <br /> подписан и отправлен!</div>
                  <div className='signature-succes-text__lower'>Следите за его статусом в разделе <br /> Исходящие</div>
              </div>
            }   
              <div className='signature-success-cancel' onClick={() => setIsOpen(!isOpen)}>Закрыть</div>
            </>
          }
        </Box>
      </Modal>
    </div>
  )
}
