import Paper from '@mui/material/Paper';
import React, { useCallback, useEffect, useState } from 'react';
import RowDocumentation from './RowDocumentation';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import { styled } from '@mui/material';

const StyledPagination = styled(TablePagination)(({ theme }) => ({
  root: {},
  '&.MuiTablePagination-root': {
    justifyContent: 'space-between',
    display: 'flex',
    width: '100%',
  },
  '.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  '.MuiTablePagination-spacer': {
    display: 'none',
  },
  '.MuiInputBase-root': {
    marginRight: 'auto',
  },
  '.MuiSelect-iconStandard': {},
}));

const columns = [
  { id: 'check', label: '', align: 'center' },
  { id: 'date', label: 'Дата', align: 'center' },
  { id: 'sender', label: 'Отправитель', align: 'center' },
  {
    id: 'document',
    label: 'Пакет документов',
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'statusDocument',
    label: 'Статус пакета документов',
    align: 'center',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'signatureStatus',
    label: 'Статус подписи',
    align: 'center',
    format: (value) => value,
  },

  {
    id: 'shelfLife',
    label: 'Срок хранения пакета',
    align: 'center',
    format: (value) => value.toFixed(2),
  },
];

export default function TableDocument({ checkDocument, rows, documentsID, setDocumentsID }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(30);
  const addToDocumentsID = useCallback(
    (documentID) => {
      setDocumentsID([...documentsID, documentID]);
    },
    [documentsID],
  );

  const removeFromDocumentsID = useCallback(
    (documentID) => {
      setDocumentsID(documentsID.filter((item) => item !== documentID));
    },
    [documentsID],
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper sx={{ width: 'calc(1600/1920*100vw)', overflow: 'hidden', padding: '0', borderRadius: 0 }}>
      <TableContainer sx={{ maxHeight: 'auto', position: 'sticky' }}>
        <Table aria-label="sticky table" stickyHeader={true}>
          <TableBody>
            {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
              return (
                <RowDocumentation
                  checkDocument={checkDocument}
                  row={row}
                  addToDocumentsID={addToDocumentsID}
                  removeFromDocumentsID={removeFromDocumentsID}
                  documentsID={documentsID}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <StyledPagination
        labelRowsPerPage="На странице"
        rowsPerPageOptions={[30, 50, 100]}
        sx={{ width: '100%', justifyContent: 'space-between', fontSize: '0.7vw' }}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        labelDisplayedRows={({ from, to, count }) => {
          return `${Math.ceil(to / rowsPerPage)} из ${Math.ceil(count / rowsPerPage)} страниц `;
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
