import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="6.4vw"
      height="7.9vw"
      fill="none"
      viewBox="0 0 123 153"
    >
      <path
        fill="#8AB528"
        d="M36.95 138.247c4.534 0 8.21-3.691 8.21-8.244 0-4.554-3.675-8.245-8.21-8.245-4.533 0-8.208 3.691-8.208 8.245 0 4.553 3.675 8.244 8.209 8.244z"
      ></path>
      <path
        fill="#FEA000"
        d="M54.167 139.452c2.597 0 4.703-2.116 4.703-4.725 0-2.609-2.105-4.724-4.703-4.724-2.598 0-4.704 2.115-4.704 4.724s2.106 4.725 4.704 4.725z"
      ></path>
      <path
        fill="#000"
        d="M72.123 152.282c-5.909 0-11.001-.147-14.08-.262-22.613-.846-47.434-3.704-51.124-7.533-11.168-11.585-5.09-79.852-4.825-82.752a.949.949 0 01.263-.572L60.725 1.166a.934.934 0 011.32-.022l60.092 57.784a.941.941 0 01.288.655c.082 3.166 1.877 77.635-9.145 86.891-5.78 4.853-26.11 5.807-41.157 5.807zM3.933 62.238c-.456 5.257-5.865 70.36 4.334 80.939 2.81 2.915 25.17 6.038 49.845 6.96 28.278 1.057 48.955-.9 53.967-5.109 8.228-6.911 9.15-58.393 8.483-85.009L61.419 3.15 3.933 62.237z"
      ></path>
      <path
        fill="#000"
        d="M107.584 139.657a.935.935 0 01-.794-.441c-9.755-15.594-37.396-56.064-48.008-56.064-6.886 0-30.042 32.131-43.926 53.728a.935.935 0 01-1.296.28.944.944 0 01-.279-1.302c3.592-5.587 35.385-54.59 45.502-54.59 13.814 0 48.141 54.621 49.596 56.946a.944.944 0 01-.795 1.443zM42.514 90.401a.927.927 0 01-.535-.17L2.492 62.593a.944.944 0 01-.234-1.31.934.934 0 011.305-.235l39.488 27.64a.944.944 0 01-.537 1.714z"
      ></path>
      <path
        fill="#000"
        d="M74.67 94.711a.935.935 0 01-.759-.388.946.946 0 01.208-1.316l46.82-34.16a.936.936 0 011.31.208.946.946 0 01-.208 1.316l-46.82 34.16a.935.935 0 01-.55.18z"
      ></path>
    </svg>
  );
}

export default Icon;