
const globalStyle = {
  styleHead: {
    fontFamily:'IBM Plex Sans',
    color: "#949DAF",
    fontSize:'0.83vw',
    paddingLeft:'1.5vw',
  },

  customStyleButton: {
    borderRadius: 0,
    backgroundColor: "#FFFFFF",
    color: "black",
    width: "8.9vw",
    height: "3.07vw",
    fontSize: "0.9vw",
    fontWeight:'400',
    textTransform:'none',
  },

  customStyleTable: {
    borderRadius:0, 
    paddingLeft:'1.5vw',
    backgroundColor:'#FFFFFF', 
    fontWeight: 400,
    color: '#181818',    
    paddingBottom:'10px',
    alignItem:"center",
    fontSize:'0.83vw',
    boxSizing:'border-box',
    fontFamily: 'IBM Plex Sans', 
    lineHeight: '150%',
    verticalAlign: "baseline",
  },
  
  customStylePackDocument:{    
    padding:'0.2vw 0.4vw',
    fontSize:'0.729vw',
    textAlign:"center",
    fontWeight:"700",     
  },

  styleStack :{
    overflowX: 'initial',
    backgroundColor:'white',
    width:'calc(1600/1920*100vw)',
    flexDirection:'row',
    alignItems:'center',
    mt:'1.04vw',
    height:'3.1vw',
    borderBottom:'1px solid #E1E4E8',
    "@media (max-width: 1120px)": {
      alignItems:'end',      
      pt:'10px',      
    }
  },
  rowCounterpartyWait:{
    "@media (max-width: 1120px)": {          
      pt:'10px',      
    }
  }
}

export default globalStyle;
