import Badge from '../../component/svgComponents/Badge';
import Loader from '../DocumentComponents/Loader';
import React, { useContext, useEffect, useState } from 'react';
import Search from '../Search';
import TableHeaders from './components/TableHeaders';
import TableUsers from './components/TableUsers';
import api from '../../api/api';
import { Box } from '@mui/material';
import { TabsContext } from '../../helpers/TabsContext';
import { StyledTab, StyledTabs, TabPanel } from './components/StyledComponents';

const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

const ClientsTabs = () => {
    const { currentPageTab, setCurrentPageTab } = useContext(TabsContext);

    const [searchValue, setSearchValue] = useState('');
    const [searchValueBlocked, setSearchValueBlocked] = useState('');

    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);

    const [blockedUsers, setBlockedUsers] = useState([]);
    const [filteredBlockedUsers, setFilteredBlockedUsers] = useState([]);

    const [isLoad, setIsLoad] = useState(true);

    const handleChange = (_, newValue) => {
        localStorage.setItem('Tab', JSON.stringify({ currentTab: 0, currentPageTab: newValue }));
        setCurrentPageTab(newValue);
    };

    const changeAccessForSendingDocs = async (userId, data) => {
        await api.post(`admin/users/access-of-sending/${userId}`, data);
        await getUsers();
    };

    const getUsers = async () => {
        const getAllUsers = async () => {
            return await api.get(`admin/users`);
        };
        getAllUsers()
            .then((response) => {
                if (response.status === 200) {
                    setUsers(response.data.users.map((value) => value.send_res));
                    setBlockedUsers(response.data.users.filter((value) => value.send_res.is_blocked).map((value) => value.send_res));
                }
            })
            .finally(() => {
                setIsLoad(false);
            });

        setSearchValue('');
        setFilteredBlockedUsers([]);
        setFilteredUsers([]);
        setSearchValueBlocked('');
    };

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        setFilteredUsers(
            users.filter((user) => {
                return Object.values(user).some((value) => value?.toString().toUpperCase().includes(searchValue?.toUpperCase()));
            }),
        );
    }, [searchValue, users]);

    useEffect(() => {
        setFilteredBlockedUsers(
            blockedUsers.filter((user) => {
                return Object.values(user).some((value) => value?.toString().toUpperCase().includes(searchValueBlocked?.toUpperCase()));
            }),
        );
    }, [searchValueBlocked, blockedUsers]);

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ width: 'calc(1600/1920*100vw)' }}>
                <StyledTabs
                    value={currentPageTab}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                    allAdmin={currentPageTab === 0}
                    blockedAdmin={currentPageTab === 1}
                >
                    <StyledTab label="Все" iconPosition="end" {...a11yProps(0)} />
                    <StyledTab
                        label="Заблокированные"
                        {...a11yProps(1)}
                        icon={blockedUsers.length ? <Badge content={blockedUsers.length} /> : null}
                        iconPosition="end"
                    />
                </StyledTabs>
                <hr
                    style={{
                        height: '2pt',
                        background: '#BDBDBD',
                        border: 'none',
                        position: 'relative',
                        bottom: '2px',
                        zIndex: 502,
                    }}
                />
            </Box>
            <TabPanel value={currentPageTab} index={0}>
                {isLoad ? (
                    <Loader />
                ) : (
                    <>
                        <Search
                            setSearchValue={setSearchValue}
                            value={searchValue}
                            onChange={(e) => {
                                setSearchValue(() => e.target.value);
                            }}
                            placeholder="Поиск по ID, ИНН, названию компании"
                        />
                        <TableHeaders />
                        <TableUsers users={searchValue ? filteredUsers : users} changeAccessForSendingDocs={changeAccessForSendingDocs} />
                    </>
                )}
            </TabPanel>
            <TabPanel value={currentPageTab} index={1}>
                {isLoad ? (
                    <Loader />
                ) : (
                    <>
                        <Search
                            setSearchValue={setSearchValueBlocked}
                            value={searchValueBlocked}
                            onChange={(e) => {
                                setSearchValueBlocked(() => e.target.value);
                            }}
                            placeholder="Поиск по ID, ИНН, названию компании"
                        />
                        <TableHeaders />
                        <TableUsers
                            users={searchValueBlocked ? filteredBlockedUsers : blockedUsers}
                            changeAccessForSendingDocs={changeAccessForSendingDocs}
                        />
                    </>
                )}
            </TabPanel>
        </Box>
    );
};

export default ClientsTabs;
