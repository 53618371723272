import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.3vw"
      height="1.3vw"
      fill="none"
      viewBox="0 0 28 28"
    >
      <path
        fill="#212529"
        fillRule="evenodd"
        d="M11.679 27.333c.7 0 1.168-.466 1.168-1.166S12.38 25 11.68 25h-5.84c-.702 0-1.169-.467-1.169-1.167V5.167c0-.7.467-1.167 1.168-1.167h5.841c.7 0 1.168-.467 1.168-1.167s-.467-1.166-1.168-1.166h-5.84c-1.986 0-3.505 1.516-3.505 3.5v18.666c0 1.984 1.519 3.5 3.504 3.5h5.841zm13.9-13.3c.117.35.117.7 0 .934 0 .116-.116.233-.233.35l-4.672 4.666c-.234.234-.584.35-.818.35-.234 0-.584-.116-.818-.35a1.127 1.127 0 010-1.633l2.687-2.683H10.51c-.701 0-1.168-.467-1.168-1.167s.467-1.167 1.168-1.167h11.214l-2.687-2.683a1.127 1.127 0 010-1.633 1.13 1.13 0 011.636 0l4.672 4.666c.058.059.088.117.117.175.03.059.058.117.117.175z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0_1374_1329"
        style={{ maskType: "luminance" }}
        width="24"
        height="27"
        x="2"
        y="1"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M11.679 27.333c.7 0 1.168-.466 1.168-1.166S12.38 25 11.68 25h-5.84c-.702 0-1.169-.467-1.169-1.167V5.167c0-.7.467-1.167 1.168-1.167h5.841c.7 0 1.168-.467 1.168-1.167s-.467-1.166-1.168-1.166h-5.84c-1.986 0-3.505 1.516-3.505 3.5v18.666c0 1.984 1.519 3.5 3.504 3.5h5.841zm13.9-13.3c.117.35.117.7 0 .934 0 .116-.116.233-.233.35l-4.672 4.666c-.234.234-.584.35-.818.35-.234 0-.584-.116-.818-.35a1.127 1.127 0 010-1.633l2.687-2.683H10.51c-.701 0-1.168-.467-1.168-1.167s.467-1.167 1.168-1.167h11.214l-2.687-2.683a1.127 1.127 0 010-1.633 1.13 1.13 0 011.636 0l4.672 4.666c.058.059.088.117.117.175.03.059.058.117.117.175z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_1374_1329)">
        <path
          fill="#181818"
          fillRule="evenodd"
          d="M0 .5h28v28H0V.5z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;