import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.25vw"
      height="1.3vw"
      fill="none"
      viewBox="0 0 24 25"
    >
      <g clipPath="url(#clip0_1227_7767)">
        <path
          fill="#181818"
          d="M16 1.5H4c-1.1 0-2 .9-2 2v14h2v-14h12v-2zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2zm0 16H8v-14h11v14z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1227_7767">
          <path
            fill="#fff"
            d="M0 0H24V24H0z"
            transform="translate(0 .5)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
