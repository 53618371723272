import React from 'react';

import SettingsContent from './SettingsContent';

const Settings = () => {
    return (        
        <div className='document-header'>
            Профиль
            <SettingsContent/>
        </div>
        
    );
};

export default Settings;