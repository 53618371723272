import React from 'react';

import LabTabsCounterParty from './LabTabsCounterParty';

const CounterParty = () => {
    return (
        <div className='document-header'>
             Контрагенты
            <LabTabsCounterParty/>
        </div>
    );
};

export default CounterParty;