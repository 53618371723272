import ASN1 from '@lapo/asn1js';
import Base64 from '@lapo/asn1js/base64';
import JSZip from 'jszip';
import api from '../api/api';

const getFileContent = (asn) => {
    const fileContent = asn.sub[1].sub[0].sub[2].sub[1].sub[0];
    const file = asn.stream.enc.slice(fileContent.posStart(), fileContent.posEnd());
    return file;
};
const formatDate = (date, withTime) => {
    const day = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate();
    const month = date.getMonth() >= 10 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    const year = date.getFullYear();

    if (withTime) {
        const hour = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours();
        const minute = date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes();
        const second = date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds();

        return `${day}.${month}.${year} ${hour}:${minute}:${second}`;
    }

    return `${day}.${month}.${year}`;
};
const getDataAboutSigner = (userData) => {
    const signerInfo = {};
    userData.sub.forEach((tag) => {
        const code = tag.sub[0].sub[0].content().split('\n')[1];
        const content = tag.sub[0].sub[1].content();

        switch (code) {
            case 'title':
                signerInfo.position = content;
                break;
            case 'organizationName':
                signerInfo.orgName = content;
                break;
            case 'givenName':
                signerInfo.fullName = signerInfo.fullName ? `${signerInfo.fullName} ${content}` : content;
                break;
            case 'surname':
                signerInfo.fullName = signerInfo.fullName ? `${content} ${signerInfo.fullName}` : content;
                break;
            default:
        }
    });

    let res = '';
    Object.keys(signerInfo).forEach((key) => {
        if (res) {
            if (key === 'fullName') res = `${signerInfo[key]}, ${res}`;
            else res += `, ${signerInfo[key]}`;
        } else {
            res = signerInfo[key];
        }
    });
    return res ? { userData: res } : {};
};
function dec2hex(str) {
    // .toString(16) only works up to 2^53
    var dec = str.toString().split(''),
        sum = [],
        hex = [],
        i,
        s;
    while (dec.length) {
        s = 1 * dec.shift();
        for (i = 0; s || i < sum.length; i++) {
            s += (sum[i] || 0) * 10;
            sum[i] = s % 16;
            s = (s - sum[i]) / 16;
        }
    }
    while (sum.length) {
        hex.push(sum.pop().toString(16));
    }
    return hex.reduce((res, value, index) => (index % 2 ? `${res}${value.toUpperCase()}` : `${res} ${value.toUpperCase()}`));
}
const getSignerInfo = (asn, result_code) => {
    try {
        const certificate = asn.sub[1].sub[0].sub[3].sub.at(-1).sub[0];

        const signerInfo = { ...getDataAboutSigner(certificate.sub[3]), ...getDataAboutSigner(certificate.sub[5]) };

        signerInfo.title = 'Файл подписан электронной подписью';
        const startDate = new Date(certificate.sub[4].sub[0].content());
        const endDate = new Date(certificate.sub[4].sub[1].content());
        const signAttrs = asn.sub[1].sub[0].sub[4].sub[0].sub[3];
        let signDate;
        signAttrs.sub.forEach((tag) => {
            if (tag.sub[0].content().split('\n')[1] === 'signingTime') signDate = new Date(tag.sub[1].sub[0].content());
        });
        signerInfo.validFrom = formatDate(startDate);
        signerInfo.validTo = formatDate(endDate);
        signerInfo.date = signDate;

        signerInfo.serialNumber = dec2hex(certificate.sub[1].content().split('\n')[1]);
        signerInfo.result = !result_code;
        return signerInfo;
    } catch (ignored) {}
};
const generateStamp = (asns) => {
    const signerInfo = {};
    Object.keys(asns).forEach(
        (user) =>
            (signerInfo[user] =
                asns[user] &&
                asns[user]
                    .filter((a) => (a.sub && a.sub.length > 1) || (a.data && a.data.sub.length > 1))
                    .map((document) => {
                        return document.hasOwnProperty('data') ? getSignerInfo(document.data, document.code) : getSignerInfo(document, 0);
                    }))
    );

    return signerInfo;
};

const getSignedFilesArchive = async (asn, baseFile, sTickets, rTickets, workflowId) => {
    const zip = new JSZip();
    await zip.loadAsync(getFileContent(baseFile ? baseFile : asn));

    const stamps = await generateStamp({
        sender: baseFile ? [baseFile, ...sTickets] : [asn, ...sTickets],
        recipient: baseFile ? [asn, ...rTickets] : null,
    });

    return stamps;
};

const downloadFileRecursive = async (documentsId, data) => {
    if (!documentsId.length) {
        return;
    }
    const res = await api.get(`/download?documentID=${documentsId[0]}`);
    if (res?.data) {
        const asn = ASN1.decode(Base64.unarmor(res?.data.file));
        let baseFile = getFileContent(asn);
        let subFile = await JSZip.loadAsync(baseFile);
        const fileName = res?.data.document.document_name;
        const filenames = Object.keys(subFile.files);
        const senderTickets = res?.data.senderTickets;
        const recipientTickets = res?.data.recipientTickets;
        const normalize = (name) => name.replaceAll(/[/\\_]/g, '');
        const isArchive = normalize(fileName) === normalize(filenames[0]);
        const isNested =
            filenames.filter((item) => !subFile.files[item].dir).length === 1 &&
            filenames.length > 1 &&
            filenames
                .find((item) => !subFile.files[item].dir)
                .split('.')
                .at(-1) !== 'pdf';
        //Получение внутренностей архива и парсинг asn
        const getFileAsn = async (filename) => ASN1.decode(Base64.unarmor(await subFile.files[filename].async('base64')));
        if (isArchive) {
            // Получение подписанных данных из архива
            subFile = await getFileAsn(filenames[0]);
            // Получение изначального файла
            baseFile = getFileContent(subFile);
        } else if (isNested) {
            const realFileName = filenames.find((item) => !subFile.files[item].dir);
            // Получение подписанных данных из вложенного архива
            subFile = await getFileAsn(realFileName);
            // Получение изначального файла
            baseFile = getFileContent(subFile);
        } else if (asn.sub[1].sub[0].sub[2].sub[1].sub[0].sub) {
            subFile = asn.sub[1].sub[0].sub[2].sub[1].sub[0].sub[0];
            baseFile = getFileContent(subFile);
        } else {
            subFile = null;
        }
        baseFile = await (await JSZip.loadAsync(baseFile)).generateAsync({ type: 'blob' });
        const infoCertificates = await getSignedFilesArchive(asn, subFile, senderTickets, recipientTickets, res.data.document.ext_workflow_id);
        data.push(infoCertificates);
    }

    return downloadFileRecursive(documentsId.slice(1), data);
};

const getInfoCertificatesFromFiles = async (documentsId) => {
    const data = [];

    await downloadFileRecursive(documentsId, data);
    return data;
};

export default getInfoCertificatesFromFiles;
