import React from "react";

function SignatureCheckedTrue() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.24vw"
      height="1.24vw"
      fill="none"
      viewBox="0 0 23 23"
    >
      <path
        fill="#8AB528"
        fillRule="evenodd"
        d="M22 11.5v-.9c0-.6-.4-1-1-1s-1 .4-1 1v.9c0 5-4 9-9 9s-9-4-9-9 4-9 9-9c1.3 0 2.5.3 3.7.8.5.2 1.1 0 1.3-.5.2-.5 0-1.1-.5-1.3-1.4-.7-3-1-4.5-1-6.1 0-11 4.9-11 11s4.9 11 11 11 11-4.9 11-11zm-.7-9.7c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4l-11 11c-.2.2-.5.3-.7.3-.2 0-.5-.1-.7-.3l-3-3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l2.3 2.3L21.3 1.8z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SignatureCheckedTrue;
