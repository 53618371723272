import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1vw"
      height="1vw"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#FEA000"
        fillRule="evenodd"
        d="M1.666 18c.417.25.834.333 1.25.333h14.167c.667 0 1.25-.25 1.667-.75.5-.416.75-1.083.75-1.75 0-.416-.084-.833-.334-1.25l-7-11.75c-.416-.583-.916-1-1.583-1.166a2.553 2.553 0 00-1.917.25c-.333.166-.666.5-.833.833L.75 14.583C.083 15.75.5 17.333 1.666 18zm.584-2.583l7-11.75a.65.65 0 01.333-.334c.417-.166.917-.083 1.167.334l7 11.75c.083.166.083.25.083.416 0 .25-.083.417-.25.584a.756.756 0 01-.583.25H2.916c-.083 0-.25 0-.333-.084-.417-.25-.583-.75-.333-1.166zm8.583-4.584V7.5c0-.5-.333-.833-.833-.833-.5 0-.834.333-.834.833v3.333c0 .5.334.834.834.834.5 0 .833-.334.833-.834zm0 3.334c0 .25-.083.416-.25.583-.167.167-.333.25-.667.25a.757.757 0 01-.583-.25.757.757 0 01-.25-.583c0-.098.029-.167.052-.224.017-.04.031-.075.031-.11 0-.083.084-.166.167-.25.083-.083.167-.166.25-.166.167-.084.333-.084.5-.084.048.048.068.069.093.077a.231.231 0 00.074.007c.041 0 .062.02.083.041.021.021.042.042.083.042.084 0 .167.083.167.083.083.084.167.167.167.25.083.084.083.25.083.334z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0_2118_5929"
        style={{ maskType: "luminance" }}
        width="20"
        height="18"
        x="0"
        y="1"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M1.666 18c.417.25.834.333 1.25.333h14.167c.667 0 1.25-.25 1.667-.75.5-.416.75-1.083.75-1.75 0-.416-.084-.833-.334-1.25l-7-11.75c-.416-.583-.916-1-1.583-1.166a2.553 2.553 0 00-1.917.25c-.333.166-.666.5-.833.833L.75 14.583C.083 15.75.5 17.333 1.666 18zm.584-2.583l7-11.75a.65.65 0 01.333-.334c.417-.166.917-.083 1.167.334l7 11.75c.083.166.083.25.083.416 0 .25-.083.417-.25.584a.756.756 0 01-.583.25H2.916c-.083 0-.25 0-.333-.084-.417-.25-.583-.75-.333-1.166zm8.583-4.584V7.5c0-.5-.333-.833-.833-.833-.5 0-.834.333-.834.833v3.333c0 .5.334.834.834.834.5 0 .833-.334.833-.834zm0 3.334c0 .25-.083.416-.25.583-.167.167-.333.25-.667.25a.757.757 0 01-.583-.25.757.757 0 01-.25-.583c0-.098.029-.167.052-.224.017-.04.031-.075.031-.11 0-.083.084-.166.167-.25.083-.083.167-.166.25-.166.167-.084.333-.084.5-.084.048.048.068.069.093.077a.231.231 0 00.074.007c.041 0 .062.02.083.041.021.021.042.042.083.042.084 0 .167.083.167.083.083.084.167.167.167.25.083.084.083.25.083.334z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_2118_5929)">
        <path
          fill="#FEA000"
          fillRule="evenodd"
          d="M0 0h20v20H0V0z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;