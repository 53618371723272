import React, { useContext } from 'react';

import { TabsContext } from '../../helpers/TabsContext';
import MessageIcon from '../svgComponents/MessageIcon';

const NoCounterParty = () => {
    const {
        currentTab,
        currentPageTab,
        setCurrentTab,
        setCurrentPageTab
    } = useContext(TabsContext);
    const handleClick = () => {
        setCurrentTab(1);
        setCurrentPageTab(0)
      }
    return (
        <div className='no-document no-wait'>
            <MessageIcon/>
            <p>У вас еще нет ни одного контрагента для международного ЭДО с компаниями в Казахстане.<br/>
                Вы можете отправить им приглашение для обмена документами.
            </p>
            <button
                className="form-button"
                type="submit"
               onClick={handleClick}
            >
            Отправить приглашение         
            </button>
        </div>
    );
};

export default NoCounterParty;