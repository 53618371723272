import React, { useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import api from '../api/api';
import ModalSettingUser from '../component/Modal/ModalSettingUser'
import { UserContext } from '../helpers/UserContext';

import Signature from './svgComponents/Signature';
import CopyIcon from './svgComponents/CopyIcon';
import ModalAlertChangeEmail from './Modal/ModalAlertChangeEmail';


const SettingsContent = () => {
    const { user,setUser } = useContext(UserContext)
    const [openModal,setOpenModal] = useState(false);
    const [openModalEmail,setOpenModalEmail] = useState(false);
  
    const togglePopup = () => {
        setOpenModal(prevState => !prevState);
    }

    const togglePopupEmail = () => {
        setOpenModalEmail(prevState => !prevState);
    }

    useEffect(() => {
        const findUser = async () => {
            try {
                const res = await api.get(`/find-user`);
                if (res?.data) {
                    setUser(res.data);
                };
            } catch(e) {
                alert(e);
                Navigate('/login');
            };
        }
        void findUser();
    }, [openModal])  

    const COMPANY_VALUES = [
        {
            title: "ИНН",
            value: user?.identification_number,            
        },
        {
            title: "КПП",
            value: user?.kpp
        },
        {
            title: "Email",
            value: user?.email
        },
        {
            title: "Пароль",
            value: "•••••••••••••••••"
        },
    ]
    const [chosenField, setChosenField] = useState(null);

    const onClickPencil = (field) => {
        setOpenModal(true)
        setChosenField(field)
    }

    return (
        <div className='setting'>
            <h1>Настройки</h1>
            <div className='setting-indicator'></div>
            <div className='setting-line' ></div>
            <div className='setting-input setting-input-company-name'>
                <div className='setting-input-company-name__value'>{user?.name}</div>
                <div className='setting-input-button' onClick={() => onClickPencil('НАИМЕНОВАНИЕ')}>
                    <Signature/>
                </div>
            </div>
            {
                COMPANY_VALUES.map(value => {                   
                    return  <div className='setting-input' key={value.title}>
                                <div className='setting-input__title'>{value.title}</div>
                                <div className='setting-input__value'>{value.value}</div>
                                <div className='setting-input-button' onClick={() => onClickPencil(value.title)}>
                                    <Signature/>
                                </div>
                            </div>
                })
            }
            <div className='setting-input setting-input-company-id'>
                <div className='setting-input__title'>
                    ID SIMPSIGN (идентификатор участника международного ЭДО)
                </div>
                <div className='setting-input__value'>
                    {user?.id}
                </div>
                <div 
                    className='setting-input-button' 
                    onClick={() =>  navigator.clipboard.writeText(user?.id)}
                >
                    <CopyIcon/>
                </div>
            </div>
            
            {openModal &&
             <ModalSettingUser
                handleClose={togglePopup}
                field={chosenField}
                modalAlertEmail={togglePopupEmail}
             />
            }
            {openModalEmail &&
             <ModalAlertChangeEmail
                handleClose={togglePopupEmail}
                field={chosenField}
             />
            }

        </div>
    );
};

export default SettingsContent;