import React from 'react';
import {useNavigate,useLocation} from "react-router-dom";
import { Formik, Form,} from 'formik';

import Header from '../component/Headers/Header';

const EmailSend = () => {
    const navigate = useNavigate();  
    const location = useLocation(); 
    const submitChack = event => {
        event.preventDefault();
        navigate('/login')
    }
    return (
             <> 
                <Header/>
                    <Formik>                                               
                        <div className='form-wrapper'>
                            <Form className='form-container'>
                                <h1 className='form-title'>Почти все готово!</h1>
                                <h6 className='form-subtitle'>
                                Для завершения регистрации необходимо подтвердить почту.
                                На почту {location.state.email} было отправлено письмо со ссылкой, по которой необходимо перейти для завершения регистрации
                                </h6>  
                                <div className="form-buttons-container">
                                    <button
                                        className="form-button"
                                        type="submit"
                                        onClick={submitChack}
                                    >
                                    Начать пользоваться                                
                                    </button>            
                                </div>              
                            </Form>                     
                        <div className='footer'>
                            <div className='footer__register  finish-register'>
                                <p>Узнать больше о сервисе SimpSign</p>
                                <a href='https://simpsign.com/' target='blank'>www.simpsign.com</a>
                            </div>
                        </div>       
                    </div>                                        
                  </Formik>
                  <div>
              </div>
            </>
    );
};

export default EmailSend;