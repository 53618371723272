import React from 'react'

import CircularProgress from '@mui/material/CircularProgress';

export default function Loader() {
  return (
    <div className='loader-container'>
        <CircularProgress  size='5vw' sx={{color: '#8AB528',}}/>
    </div>
  )
}
