import React, {createContext, useState} from "react";

const initialState = {
    scroll: false,
    setCurrentTab: (boolean) => {},
}

export const ScrollContext = createContext(initialState);

export const ScrollContextProvider = ({children}) => {
    
    const [scroll, setScroll] = useState(false)

    return (
        <ScrollContext.Provider value={{
            scroll,
            setScroll,
        }}>
            {children}
        </ScrollContext.Provider>
    )
}
