import React from 'react';

import LabTabsDocument from './LabTabsDocument';

const Document = () => {
    
    return (
        <div className='document-header'>
            Документы
            <LabTabsDocument />
        </div>
    );
};

export default Document;