import * as pkijs from 'pkijs';
import * as pvutils from 'pvutils';
import dayjs from 'dayjs';

export default (cms) => {
    const signData = {
        signingTime: '',
        certData: [],
    };
    const cmsSignedSimpl = cms;

    const signermap = {
        '1.2.840.113549.1.9.5': 'signingTime',
    };
    if (cmsSignedSimpl.signerInfos) {
        for (let j = 0; j < cmsSignedSimpl.signerInfos.length; j++) {
            const infoData = cmsSignedSimpl.signerInfos[j];
            for (let i = 0; i < infoData.signedAttrs.attributes.length; i++) {
                let typeval = signermap[infoData.signedAttrs.attributes[i].type];
                if (typeof typeval === 'undefined') {
                    continue;
                }
                const timeString = infoData.signedAttrs.attributes[i].values.toString();
                signData.signingTime = timeString;
            }
        }
    }

    const rdnmap = {
        '2.5.4.6': '2.5.4.6',
        '2.5.4.10': '2.5.4.10',
        '2.5.4.11': '2.5.4.11',
        '2.5.4.3': '2.5.4.3',
        '2.5.4.7': '2.5.4.7',
        '2.5.4.8': '2.5.4.8',
        '2.5.4.12': '2.5.4.12',
        '2.5.4.42': '2.5.4.42',
        '2.5.4.43': '2.5.4.43',
        '2.5.4.4': '2.5.4.4',
        '1.2.840.113549.1.9.1': '1.2.840.113549.1.9.1',
    };

    if (cmsSignedSimpl.certificates) {
        for (let j = 0; j < cmsSignedSimpl.certificates.length; j++) {
            const certItem = {
                serialNumber: '',
                issuer: {},
                subject: {},
            };
            const cert = cmsSignedSimpl.certificates[j];
            if (!(cert instanceof pkijs.Certificate)) {
                continue;
            }
            const serialNumber = pvutils.bufferToHexCodes(cert.serialNumber.valueBlock.valueHexView);
            const validTo = dayjs(cert.notAfter.value).format('DD.MM.YYYY');
            const validFrom = dayjs(cert.notBefore.value).format('DD.MM.YYYY');

            certItem.serialNumber = serialNumber;
            certItem.validFrom = validFrom;
            certItem.validTo = validTo;

            for (let i = 0; i < cert.issuer.typesAndValues.length; i++) {
                let typeval = rdnmap[cert.issuer.typesAndValues[i].type];
                if (typeof typeval === 'undefined') typeval = cert.issuer.typesAndValues[i].type;

                const subjval = cert.issuer.typesAndValues[i].value.valueBlock.value;

                certItem.issuer[typeval] = subjval;
            }

            for (let i = 0; i < cert.subject.typesAndValues.length; i++) {
                let typeval = rdnmap[cert.subject.typesAndValues[i].type];
                if (typeof typeval === 'undefined') typeval = cert.subject.typesAndValues[i].type;

                const subjval = cert.subject.typesAndValues[i].value.valueBlock.value;
                certItem.subject[typeval] = subjval;
            }

            signData.certData.push(certItem);
        }
    }
    return signData;
};
