import "../styles/SelectButton.css";
import React, { useEffect, useState } from "react";
import clsx from "clsx";

const SelectButton = ({ changeAccessForSendingDocs, userId, isBlocked }) => {
    const [isLoadingButton, setIsLoadingButton] = useState(false);

    return (
        <div
            className="select-button"
            onClick={() => {
                setIsLoadingButton(true);
                changeAccessForSendingDocs(userId, {
                    is_blocked: !isBlocked,
                }).then(() => {
                    setIsLoadingButton(false);
                });
            }}
        >
            {/* {isLoadingButton && <div className="loader"></div>} */}
            <div
                className={clsx('select-button__thumb', {
                    'select-button__thumb--blocked': isBlocked,
                })}
            ></div>
        </div>
    );
};

export default SelectButton;
