import React from 'react';

import globalStyle from './customStyleButton'

export const CREATED = 'СОЗДАН';
export const SENT = 'ОТПРАВЛЕН';
export const RECEIVED = 'ПОЛУЧЕН';
export const SIGNATURE_EXPECTED = 'ОЖИДАЕТСЯ ПОДПИСЬ';
export const NOT_DEMANDED = 'НЕ ВОСТРЕБОВАН';
export const SIGNATURE_DENIED = 'ОТКАЗАНО В ПОДПИСИ';
export const DELETED = 'УДАЛЕН';
export const OVERDUE_DOCUMENT = 'НЕДОСТУПЕН';

export const ON_VALIDATION = 'на валидации';
export const NOT_VALIDATED = 'не валидирована';
export const VALIDATED = 'валидирована';


const StatusDocument = ({ documentStatus, senderSignatureStatus, recipientSignatureStatus }) => {

    const styleStatus= {
        onValidation: {
            color:"#FEA000",  
            border:'1px solid #FEA000', 
        },
        notValidated: {
            color:"#EB5757",  
            border:'1px solid #EB5757', 
        },
        validated: {
            color:"#8AB528",  
            border:'1px solid #8AB528', 
        }
    }
    
    const getStyles = () => {
        if (documentStatus === SIGNATURE_DENIED || documentStatus === NOT_DEMANDED || documentStatus === OVERDUE_DOCUMENT) {
            return styleStatus.notValidated;
        }        
        if (senderSignatureStatus === NOT_VALIDATED || recipientSignatureStatus === NOT_VALIDATED) {
            return styleStatus.notValidated;
        } else if (senderSignatureStatus === ON_VALIDATION || recipientSignatureStatus === ON_VALIDATION) {
            return styleStatus.onValidation;
        } else if (senderSignatureStatus === VALIDATED && (recipientSignatureStatus === VALIDATED || recipientSignatureStatus === '')) {
            return styleStatus.validated;
        }
    
    }

    return (
        <span style={{...getStyles(),...globalStyle.customStylePackDocument}}>{documentStatus}</span>
    );
};

export default StatusDocument;