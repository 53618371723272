import ClientsPage from '../pages/ClientsPage';
import CounterParty from './CounterParty';
import Document from './Document';
import HeaderContent from './Headers/HeaderContent';
import Loader from './DocumentComponents/Loader';
import React, { useContext, useEffect, useRef } from 'react';
import Settings from './Settings';
import { useScrollTrigger } from '@mui/material';
import { ScrollContext } from '../helpers/ScrollContext';
import { TabsContext, navigateTabFromLocalStorage } from '../helpers/TabsContext';
import { UserContext } from '../helpers/UserContext';

const Content = ({ isLoading }) => {
    const { user, isAdmin } = useContext(UserContext);

    const { currentTab, setCurrentPageTab, setCurrentTab } = useContext(TabsContext);
    const { scroll, setScroll } = useContext(ScrollContext);
    const Node = useRef(null);
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        target: Node.current || undefined,
        threshold: 262,
    });
    setScroll(trigger);

    useEffect(() => {
        navigateTabFromLocalStorage(setCurrentTab, setCurrentPageTab);
    }, []);

    return (
        <div className="content" ref={Node}>
            <HeaderContent />
            {(() => {
                if (isAdmin) {
                    switch (currentTab) {
                        case 2:
                            return <Settings />;
                        default:
                            return <ClientsPage />;
                    }
                } else {
                    switch (currentTab) {
                        case 0:
                            return <Document />;
                        case 1:
                            return <CounterParty />;
                        case 2:
                            return <Settings />;
                        case 3:
                            return <ClientsPage />;
                        default:
                            return null;
                    }
                }
            })()}
        </div>
    );
};

export default Content;
