import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.04vw"
      height="1.04vw"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#212529"
        d="M17.5 10c0 .5-.333.833-.833.833H5.334l3.583 3.584a.805.805 0 010 1.166.756.756 0 01-.583.25.756.756 0 01-.583-.25l-5-5c-.084-.083-.167-.166-.167-.25-.083-.166-.083-.416 0-.666.083-.084.083-.167.167-.25l5-5a.806.806 0 011.166 0 .806.806 0 010 1.166L5.334 9.167h11.333c.5 0 .834.333.834.833z"
      ></path>
      <mask
        id="mask0_1445_4795"
        style={{ maskType: "luminance" }}
        width="16"
        height="12"
        x="2"
        y="4"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M17.5 10c0 .5-.333.833-.833.833H5.334l3.583 3.584a.805.805 0 010 1.166.756.756 0 01-.583.25.756.756 0 01-.583-.25l-5-5c-.084-.083-.167-.166-.167-.25-.083-.166-.083-.416 0-.666.083-.084.083-.167.167-.25l5-5a.806.806 0 011.166 0 .806.806 0 010 1.166L5.334 9.167h11.333c.5 0 .834.333.834.833z"
        ></path>
      </mask>
      <g mask="url(#mask0_1445_4795)">
        <path
          fill="#212529"
          fillRule="evenodd"
          d="M0 0h20v20H0V0z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
