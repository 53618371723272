import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={"1.25vw"}
    height={"1.25vw"}
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <rect width={23} height={23} x={0.5} y={0.5} fill="#8AB528" rx={3.5} />
    <path
      fill="#212529"
      d="m20.7 7.7-11 11c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3l-5-5c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L9 16.6 19.3 6.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4Z"
    />
    <mask
      id="a"
      width={18}
      height={13}
      x={3}
      y={6}
      maskUnits="userSpaceOnUse"
      style={{
        maskType: "luminance",
      }}
    >
      <path
        fill="#fff"
        d="m20.7 7.7-11 11c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3l-5-5c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0L9 16.6 19.3 6.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4Z"
      />
    </mask>
    <g fill="#fff" mask="url(#a)">
      <path d="M0 0h24v24H0z" />
      <path fillRule="evenodd" d="M0 0h24v24H0V0Z" clipRule="evenodd" />
    </g>
    <rect width={23} height={23} x={0.5} y={0.5} stroke="#8AB528" rx={3.5} />
  </svg>
)
export default SvgComponent
