import React, { useState,useLayoutEffect } from 'react';
import {Link ,useNavigate} from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import validator from 'validator';

import api from "../api/api";
import Header from '../component/Headers/Header';

const MESSAGE_EMAIL_IS_INVALID = "Email не соответствует требованиям";

export default function ForgetPassword () {
  
    const [netWorkError,setNetWorkError] = useState()
    const [serverError,setServerError] = useState('');
    const navigate = useNavigate();

    useLayoutEffect(() => {  
      let formSelector = document.getElementsByClassName("form-wrapper")[0]
      formSelector.setAttribute("style", "margin-bottom: 0;")
      setTimeout(()=> {
        formSelector.removeAttribute("style")
      }, 0)      
    }, []) 

    const [register, setRegister] = useState(() => {
        return {
            email: "",                
        }
    })
    const initialErrorsList = {
      email: "",         
    }

    const [errorsList, setErrorsList] = useState(initialErrorsList) 
    const removeFieldError = (field) => {
        setErrorsList(prevState => ({...prevState, [`${field}`]: ''}))
    } 

    const changeInputRegister = event => {   
        const changedValue = event.target.value
        const fieldName = event.target.name
        removeFieldError(fieldName);
        setRegister(prev => {
            return {
                ...prev,
                [fieldName]: changedValue,
            }
        })
    }

    const submitCheck = async (event) => {
      event.preventDefault();
      let wasError = false;
      const email = register.email.trim();
      if (!validator.isEmail(email)) {
        setErrorsList(prevState => ({...prevState, email: MESSAGE_EMAIL_IS_INVALID}))
        wasError = true;
      }
      if (wasError) {
        return;
      }
      try {
        const res = await api.post(
          "/auth/restore-password/", {
          email: register.email,              
       });
       if (res?.data) {
          navigate('/email-send',{state:{email:email}});
       }
      } catch (e) {
        if(e.message === "Network Error") {
          setNetWorkError(e.message)
        };
        setServerError(e.response.data.message);
        setServerError(e.response.data.error); 
      };
    } 

    return(
      <>       
        <Header/>
        <Formik            
          initialValues={register}
          onSubmit={submitCheck}
        >            
        <div className='form-wrapper'>
          <Form className='form-container'>
                  <h1 className='form-title'>Восстановить пароль</h1>
                  <h6 className='form-subtitle'>Введите email, который вы использовали при регистрации. На него придет ссылка для восстановления пароля.</h6>
                  <h6 className='form-subtitle'>Если Вы не помните email — свяжитесь со службой тех. поддержки: support@simpsign.com</h6>
                  <div className='field-container'>
              <p className="field-title">Email</p>    
              <Field
                className={Boolean(errorsList?.email) || serverError ? "input_error": "input"}
                label="Email"
                name="email"
                type="email" 
                onChange={changeInputRegister}              
                value={register.email}            
              />
              {Boolean(errorsList?.email) ? (<div className="valid-error">{errorsList.email}</div>) : null}   
                {(<div className="valid-error">{serverError? serverError:""}</div>)}
              </div>

            
              <div className="form-buttons-container">
                <button
                  className="form-button button-login"
                  type="submit"
                  onClick={submitCheck}
                >
                  Восстановить              
                </button>
              </div>
              <div className='forget-password-links'> <Link className='form__link' to="/login"><span>Войти</span></Link> или <Link className='form__link' to="/">Зарегистрироваться</Link></div>       
          </Form>
          <p className="form-error">
            { netWorkError ? <span className="valid-error">На сервере произошла непредвиденная ошибка. Обратитесь в техническую поддержку.</span> :""}
          </p>
          <div className='footer' style={{marginTop:"8.8vw"}}>
            <div className='footer__register'>
            <p>Узнать больше о сервисе SimpSign</p>
            <a href='https://simpsign.com/' target='blank'>www.simpsign.com</a>
            </div>                 
          </div>   
        </div>             
        </Formik>
      </>
    )        
}
