import React from "react";

function SignatureCheckedFalse() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.24vw"
      height="1.24vw"
      fill="none"
      viewBox="0 0 22 23"
    >
      <path
        fill="#BDBDBD"
        fillRule="evenodd"
        d="M0 11.5C0 5.4 4.9.5 11 .5s11 4.9 11 11-4.9 11-11 11-11-4.9-11-11zm2 0c0 5 4 9 9 9s9-4 9-9-4-9-9-9-9 4-9 9z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SignatureCheckedFalse;
