import CounterPartyButton from "./CounterPartyButton";
import DocumentButton from "./DocumentButton";
import HelpIcon from "./svgComponents/HelpIcon";
import IconSimpSign from "../component/svgComponents/IconSimpSign";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { TabsContext } from "../helpers/TabsContext";
import { UserContext } from "../helpers/UserContext";

const DOCUMENT_TAB_INDEX = 0;
const COUNTER_TAB_INDEX = 1;

const SideBar = () => {
    const { user, isAdmin } = useContext(UserContext);
    const { currentTab, setCurrentTab, setCurrentPageTab } = useContext(TabsContext);
    const isDocumentTabActive = currentTab === 0;
    const isProfileTabActive = currentTab === 2;
    const navigate = useNavigate();

    const handleClick = (index) => {
        setCurrentTab(index);
        setCurrentPageTab(1);
        localStorage.setItem('Tab', JSON.stringify({ currentTab: index, currentPageTab: 1 }));
    };

    return (
        <div className="side-bar">
            <div className="side-bar__icon">
                <a href="https://simpsign.com/" target="blank">
                    <IconSimpSign />
                </a>
            </div>
            {!isAdmin && (
                <>
                    <DocumentButton isActive={isDocumentTabActive} onClick={() => handleClick(DOCUMENT_TAB_INDEX)} />
                    <CounterPartyButton isActive={!isDocumentTabActive && !isProfileTabActive} onClick={() => handleClick(COUNTER_TAB_INDEX)} />
                    <a href="https://simpsign.com/instructions" target="blank">
                        <HelpIcon className="help-icon" />
                    </a>
                </>
            )}
        </div>
    );
};

export default SideBar;
