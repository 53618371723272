import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.25vw"
      height="1.3vw"
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        fill="#949DAF"
        fillRule="evenodd"
        d="M21 5.5h-4v-1c0-1.7-1.3-3-3-3h-4c-1.7 0-3 1.3-3 3v1H3c-.6 0-1 .4-1 1s.4 1 1 1h1v13c0 1.7 1.3 3 3 3h10c1.7 0 3-1.3 3-3v-13h1c.6 0 1-.4 1-1s-.4-1-1-1zm-12-1c0-.6.4-1 1-1h4c.6 0 1 .4 1 1v1H9v-1zm8 17c.6 0 1-.4 1-1v-13H6v13c0 .6.4 1 1 1h10zm-6-10v6c0 .6-.4 1-1 1s-1-.4-1-1v-6c0-.6.4-1 1-1s1 .4 1 1zm4 6v-6c0-.6-.4-1-1-1s-1 .4-1 1v6c0 .6.4 1 1 1s1-.4 1-1z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0_973_347"
        style={{ maskType: "luminance" }}
        width="20"
        height="23"
        x="2"
        y="1"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M21 5.5h-4v-1c0-1.7-1.3-3-3-3h-4c-1.7 0-3 1.3-3 3v1H3c-.6 0-1 .4-1 1s.4 1 1 1h1v13c0 1.7 1.3 3 3 3h10c1.7 0 3-1.3 3-3v-13h1c.6 0 1-.4 1-1s-.4-1-1-1zm-12-1c0-.6.4-1 1-1h4c.6 0 1 .4 1 1v1H9v-1zm8 17c.6 0 1-.4 1-1v-13H6v13c0 .6.4 1 1 1h10zm-6-10v6c0 .6-.4 1-1 1s-1-.4-1-1v-6c0-.6.4-1 1-1s1 .4 1 1zm4 6v-6c0-.6-.4-1-1-1s-1 .4-1 1v6c0 .6.4 1 1 1s1-.4 1-1z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_973_347)">
        <path
          fill="#949DAF"
          fillRule="evenodd"
          d="M0 .5h24v24H0V.5z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;

