import React from "react";

import MyDocument from '../../assets/document/Типовой договор на услуги сервиса SimpSign ЭДО_ООО_Удобная_Подпись.docx'
import CrossIcon from "../svgComponents/CrossIcon";

const Popup = props => {
  return (
    <div className="popup-box">
      
      <div className="box">
        <span className="close-icon" onClick={props.handleClose}><CrossIcon/></span>
       <h1>Как начать пользоваться сервисом международного ЭДО в системе 1С</h1>
       <ol>
        <li>Подписать договор об эксплуатации сервиса SimpSign Courier с ООО «УДОБНАЯ ПОДПИСЬ» и получить Ваш личный номер ID для ведения международного ЭДО
        </li>
        <li>Установить «коробочное» решение (модуль расширения) SimpSign Courier для 1С при помощи специалистов компании «УДОБНАЯ ПОДПИСЬ» или самостоятельно. Мы расскажем и покажем, как просто работать с системой SimpSign. Все абсолютно бесплатно
        </li>
        <li>Заключить соглашение о переходе на ЭДО со своими международными контрагентами
        </li>
        <li>Иметь в наличии квалифицированную электронную подпись (КЭП) и КриптоПро для клиентов из России, и ЭЦП и NCLayer для клиентов из Казахстана для подписания документов. Каждая сторона подписывает документы электронной подписью, выданной в своей стране
        </li>
        <li>Приступить к работе с сервисом SimpSign Courier через 1С!</li>
       </ol>
        <p className="box__footer">
          <a
            style={{color:"#000000", textDecoration: 'none'}}
            href="https://simpsign.com/" 
            target="_blank" rel="noreferrer"
          >
            www.simpsign.com
          </a>
          <br/>+7 (495) 532-00-10
        </p>
      </div>
    </div>
  );
};
 
export default Popup;