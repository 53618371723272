import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.25vw"
      height="1.3vw"
      fill="none"
      viewBox="0 0 24 25"
    >
      <path
        fill="#949DAF"
        fillRule="evenodd"
        d="M20 8.483C20 4.093 16.4.5 12 .5S4 4.093 4 8.483c0 2.595 1.2 4.89 3.1 6.287L6 23.352c-.1.4.1.799.4.998.3.2.7.2 1.1 0l4.5-2.694 4.5 2.694c.1.1.3.1.5.1s.4-.1.5-.2c.3-.2.5-.598.4-.997l-1.1-8.483c2-1.397 3.2-3.692 3.2-6.287zm-14 0c0-3.293 2.7-5.987 6-5.987s6 2.694 6 5.987-2.7 5.988-6 5.988-6-2.694-6-5.988zm6.5 11.177l3.2 1.896-.7-5.688c-.9.4-1.9.599-3 .599s-2.1-.2-3-.599l-.7 5.688 3.2-1.896c.3-.2.7-.2 1 0z"
        clipRule="evenodd"
      ></path>
      <mask
        id="mask0_973_627"
        style={{ maskType: "luminance" }}
        width="16"
        height="25"
        x="4"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M20 8.483C20 4.093 16.4.5 12 .5S4 4.093 4 8.483c0 2.595 1.2 4.89 3.1 6.287L6 23.352c-.1.4.1.799.4.998.3.2.7.2 1.1 0l4.5-2.694 4.5 2.694c.1.1.3.1.5.1s.4-.1.5-.2c.3-.2.5-.598.4-.997l-1.1-8.483c2-1.397 3.2-3.692 3.2-6.287zm-14 0c0-3.293 2.7-5.987 6-5.987s6 2.694 6 5.987-2.7 5.988-6 5.988-6-2.694-6-5.988zm6.5 11.177l3.2 1.896-.7-5.688c-.9.4-1.9.599-3 .599s-2.1-.2-3-.599l-.7 5.688 3.2-1.896c.3-.2.7-.2 1 0z"
          clipRule="evenodd"
        ></path>
      </mask>
      <g mask="url(#mask0_973_627)">
        <path
          fill="#949DAF"
          fillRule="evenodd"
          d="M0 .5h24v24H0V.5z"
          clipRule="evenodd"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;
