import React from 'react';

import CertificateIcon from '../svgComponents/CertificateIcon';

const NoDocumentInComing = () => {
    return (
        <div className='no-document in-coming'>
           <CertificateIcon />
           <h1 >У вас пока нет входящих документов</h1>
           <p>На этой странице будут показаны документы,<br/>полученные от вашего контрагента</p>
        </div>
    );
};

export default NoDocumentInComing;