import React from "react";

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '27.05vw',
    minHeight: '13.15vw',
    bgcolor: 'background.paper',
    padding: '2.69vw 3.88vw',
    fontFamily: 'IBM Plex Sans',
    boxSizing: 'border-box',
    outline: 'none',
};

export default function ModalSignError({isOpen, errorMessage = '', onClose}) {
    return (
      <Modal
        open={isOpen}
        onClose={onClose}
      >
          <Box sx={style}>
              <div className='signature-waiting-text'>
                  {errorMessage}
              </div>
          </Box>
      </Modal>
    )
}
