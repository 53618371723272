import React from 'react';


const PENDING = 'ОЖИДАЕТСЯ ОТВЕТ';
const REJECTED = 'ОТКАЗАНО';
const OVERDUE = 'ПРОСРОЧЕНО';

const StatusInvite = ({props}) => {
    const item = props.toUpperCase() ;
  
    const styleStatus= {     
    pending: {
        color:"#BDBDBD",  
        border:'1px solid #BDBDBD', 
        width:'8.07vw',  
        padding:'0.2vw 0.1vw',
        fontSize:'0.729vw',
        textAlign:"center",
        fontWeight:"700",    
    },
    rejected: {
        padding:'0.2vw 0.1vw',
        fontSize:'0.729vw',
        textAlign:"center",
        fontWeight:"700",    
        color:"#EB5757",  
        border:'1px solid #EB5757',  
        width:'4.8vw', 
    },
    overdue: {
        padding:'0.2vw 0.1vw',
        fontSize:'0.729vw',
        textAlign:"center",
        fontWeight:"700",    
        color:"#EB5757",  
        border:'1px solid #EB5757',  
        width:'5.88vw', 
    },     
}

const getStyles = () => {
    switch(item){
    case PENDING:
        return styleStatus.pending;
        break;
    case REJECTED:
        return styleStatus.rejected;
        break;
    case OVERDUE:
        return styleStatus.overdue;
        break;
    default: return '';
    }
    
}

    return (
        <div style={{...getStyles(),
        }} >
            {item}
        </div>
    );
};

export default StatusInvite;