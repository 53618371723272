import "./App.css";
import ClientsPage from "./pages/ClientsPage";
import EmailSend from "./pages/EmailSend";
import FinishRegister from "./pages/FinishRegister";
import ForgotPassword from "./pages/ForgotPassword";
import LoginPage from "./pages/LoginPage";
import React from "react";
import RegisterPage from "./pages/RegisterPage";
import ResetPassword from "./pages/ResetPassword";
import UserPage from "./pages/UserPage";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/' element={<LoginPage />} />
                <Route path='/login' element={<LoginPage />} />
                <Route path='/registration' element={<RegisterPage />} />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/reset-password' element={<ResetPassword />} />
                <Route path='/email-send' element={<EmailSend />} />
                <Route path='/user-page' element={<UserPage />} />
                <Route path='/clients' element={<ClientsPage />} />

                <Route path='/finish-register' element={<FinishRegister />} />
                <Route path='*' element={<Navigate to='/user-page' replace={true} />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
