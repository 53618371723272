import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.3vw"
      height="8.9vw"
      fill="none"
      viewBox="0 0 160 172"
    >
      <path
        fill="#FEA000"
        d="M47.525 121.05a.93.93 0 01-.605-.223.945.945 0 01-.11-1.327c4.085-4.852 8.686-9.386 13.135-13.77 2.105-2.074 4.093-4.033 5.947-5.948a.935.935 0 011.326-.018c.371.362.38.958.019 1.331-1.87 1.93-3.865 3.897-5.978 5.979-4.42 4.354-8.989 8.856-13.018 13.642a.935.935 0 01-.716.334z"
      ></path>
      <path
        fill="#000"
        d="M46.172 133.943a.92.92 0 01-.323-.058c-4.77-1.764-10.312-4.822-10.824-12.888a.938.938 0 111.871-.12c.35 5.521 3.222 8.883 9.6 11.24a.943.943 0 01-.324 1.826z"
      ></path>
      <path
        fill="#000"
        d="M23.427 155.838c-3.356 0-6.22-1.37-8.204-3.172-4.177-3.798-5.428-9.83-3.042-14.669 2.886-5.851 8.828-9.32 16.352-13.712 4.645-2.711 9.91-5.785 15.558-10.027a.936.936 0 011.457.477c1.435 4.683 3.921 6.028 6.783 7.192a.94.94 0 01.465 1.335 900.4 900.4 0 00-1.87 3.349c-6.07 10.895-12.345 22.162-19.725 26.804-2.746 1.728-5.385 2.423-7.774 2.423zm20.758-39.308c-5.342 3.914-10.302 6.811-14.71 9.383-7.252 4.233-12.979 7.576-15.614 12.92-2.016 4.088-.938 9.201 2.62 12.437 3.739 3.398 8.869 3.604 13.724.55 6.973-4.385 13.407-15.936 19.083-26.127.455-.818.909-1.631 1.36-2.436-2.492-1.12-4.92-2.76-6.463-6.727zM73.946 105.161c-3.004 0-6.119-1.194-8.156-4.192-3.237-4.76-1.72-10.475 1.451-13.66a.934.934 0 011.326 0 .944.944 0 010 1.332c-2.625 2.636-3.89 7.352-1.229 11.266 2.553 3.755 6.902 3.862 9.85 2.812a.941.941 0 01.627 1.774c-1.196.428-2.522.668-3.87.668z"
      ></path>
      <path
        fill="#8AB528"
        d="M137.512 64.395c0 19.089-12.698 37.014-31.705 37.014-19.005 0-37.121-17.925-37.121-37.014 0-19.089 15.406-34.564 34.413-34.564 19.006 0 34.413 15.475 34.413 34.564z"
      ></path>
      <path
        fill="#000"
        d="M104.798 108.816c-11.424 0-23.074-4.75-32.46-14.177C54.05 76.27 53 46.287 70.047 29.165c8.42-8.456 20.137-12.879 32.995-12.459 12.47.41 24.474 5.518 32.934 14.016 8.401 8.437 12.743 20.122 12.225 32.901-.501 12.342-5.653 24.306-14.135 32.825-8.228 8.265-18.651 12.367-29.268 12.368zm-3.269-90.252c-11.205 0-22.213 3.954-30.156 11.933-16.329 16.4-15.279 45.163 2.291 62.81 17.352 17.428 42.75 18.204 59.077 1.81 17.232-17.308 18.125-46.777 1.91-63.063-8.925-8.965-21.136-13.49-33.122-13.49z"
      ></path>
      <path
        fill="#FEA000"
        d="M84.79 88.93a.93.93 0 01-.596-.216c-9.198-7.623-14.768-18.173-15.281-28.945-.425-8.917 2.757-17.259 8.959-23.488 10.539-10.585 26.285-13.819 40.117-8.238.48.195.714.743.52 1.225a.939.939 0 01-1.22.523c-13.133-5.3-28.085-2.23-38.091 7.821-5.824 5.85-8.812 13.686-8.413 22.067.488 10.243 5.81 20.296 14.603 27.582.399.33.455.925.126 1.325a.935.935 0 01-.723.344z"
      ></path>
      <path
        fill="#fff"
        d="M104.657 83.677a.94.94 0 01-.919-.763c-.133-.687-1.261-6.796.07-9.672 1.107-2.394 3.46-3.987 5.951-5.672 4.508-3.05 9.617-6.508 9.586-15.86-.008-2.648-1.612-5.757-4.186-8.115-3.294-3.018-7.709-4.448-12.43-4.027-11.53 1.028-13.689 12.042-13.775 12.51a.939.939 0 01-1.092.756.94.94 0 01-.753-1.094c.023-.128 2.482-12.89 15.455-14.048 5.252-.468 10.173 1.135 13.86 4.512 2.948 2.702 4.786 6.342 4.797 9.501.034 10.357-5.759 14.277-10.414 17.427-2.367 1.602-4.412 2.985-5.298 4.904-.912 1.97-.259 6.818.07 8.52a.942.942 0 01-.922 1.121zM105.93 91.179a.936.936 0 01-.796-.441l-1.381-2.215a.942.942 0 01.297-1.297.933.933 0 011.291.298l1.382 2.214a.943.943 0 01-.793 1.44z"
      ></path>
    </svg>
  );
}

export default Icon;
