import React from 'react';

import CrossIcon from '../svgComponents/CrossIcon';

const ModalAlertChangeEmail = ({handleClose}) => {
    return (
        <div className="popup-box-settings">      
        <div className="box-settings">
          <span className="close-icon-settings" onClick={handleClose}><CrossIcon/></span>
             <>
                <h1 className="box-settings-description">Email изменен </h1>
                <div className="box-settings-input-title">Для завершения операции необходимо подтвердить почту.
                   На указанный Email отправлено письмо со ссылкой, по которой необходимо перейти для завершения операции.
                </div>
              </>         
          <div className='box-settings-buttons'>
              <button
                className="form-button"
                type="submit"
                onClick={handleClose}
              >
                  Продолжить
              </button>
          </div>

       
        </div>
      </div>
    );
};

export default ModalAlertChangeEmail;