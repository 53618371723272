import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.04vw"
      height="1.04vw"
      fill="none"
      viewBox="0 0 20 20"
    >
      <g clipPath="url(#clip0_1884_6997)">
        <path
          fill="#BDBDBD"
          d="M13.333.833h-10c-.917 0-1.667.75-1.667 1.667v11.667h1.667V2.5h10V.833zm2.5 3.334H6.666c-.917 0-1.667.75-1.667 1.666V17.5c0 .917.75 1.667 1.667 1.667h9.167c.916 0 1.666-.75 1.666-1.667V5.833c0-.916-.75-1.666-1.666-1.666zm0 13.333H6.666V5.833h9.167V17.5z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1884_6997">
          <path fill="#fff" d="M0 0H20V20H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;