import dayjs from "dayjs";

const checkExpirationDateOfCertificate = (certs, signThumbprint) => {
    let selectCertificate = certs.find((certificate) => certificate.thumbprint === signThumbprint);
    let currentDate = dayjs();
    let expirationDateCertificate = dayjs(selectCertificate.validTo);

    const diffDays = expirationDateCertificate.diff(currentDate, 'days');

    if (diffDays < 30) {
        return {
            signThumbprint: signThumbprint,
        };
    } else {
        return null;
    }
};

export default checkExpirationDateOfCertificate;
