import React from 'react';

import api from '../../api/api';

const InviteCounterParty = ({counterInviteParty, setIsCounterPartyChange, refetchData}) => {

    const sendToAccept = async (recipient_id,accept) => {
          const data = {
            'recipient_id': recipient_id,
            'accept':accept,
          }
          await api.post( "/accept-counterparty", data);
          await refetchData();
          setIsCounterPartyChange(true);
    }

    return (
        <>
            <div className='invite-counter-party'>
              Чтобы согласиться на обмен электронными документами и добавить организацию из Казахстана
              в свой список контрагентов, нажмите «Принять приглашение». Если приглашение поступило вам по ошибке, или вы пока не готовы
              перейти на электронный документооборот с данной организацией, то нажмите кнопку «Отказать».
            </div>
          {  counterInviteParty.map((item)=>
            <div className='invite-counter-party-button'>
                <div className='invite-counter-party-button__description'>
                <p className='invite-counter-party-button__description name-company'>{item.company}</p>
                <p className='invite-counter-party-button__description bin-company'>
                    <p style={{color:"#949DAF"}}>
                    БИН 
                    </p>
                     {item.bin}
                    </p>
                </div> 
                <p className='invite-counter-party-button__description date-invite' style={{color:'#949DAF'}}>{item.date.split('T')[0].split('-').reverse().join('.')}</p>
            <div className='new-document__button-block buttons-invite'>
                <button
                className="form-button button-accepted"
                type="submit"
                  onClick={()=>sendToAccept(item.recipient_id,true)}
                >
                       Принять приглашение      
                </button>
                <button
                className="form-button button-refuse"
                type="submit"
                onClick={()=>sendToAccept(item.recipient_id,false)}
                >
                    Отказать    
                </button>

          </div>
            </div>)}

        </>
    );
};

export default InviteCounterParty;