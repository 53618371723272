import ClientsTabs from "../component/ClientsTabs/ClientsTabs";
import React from "react";

const ClientsPage = () => {
    return (
        <div className='document-header'>
            Клиенты
            <ClientsTabs />
        </div>
    );
};

export default ClientsPage;
